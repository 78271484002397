
import "./App.css";
import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
   
} from "react-router-dom";
import Homepage1 from "./Pages/Homepage1";
// import Aboutus from './Pages/Aboutus';
import Contactus from './Pages/Contactus';
import Wevdev from './Pages/Webdev';
import Digitalmarketing from "./Pages/Digitalmarketing";
import Performancemarketing from './Pages/Performancemarketing';
import Mobiledev from "./Pages/Mobiledev";
import Socialmedia from "./Pages/Socialmedia";
import Privacypolicy from "./Pages/Privacypolicy";
import Termscondition from "./Pages/Termscondition";
import Graphicdesigning from "./Pages/Graphicdesigning";
import Seo from "./Pages/Seo";
import Teams from "./Pages/Teams";
import ProjectScreen from "./Pages/ProjectScreen";
import MonovougeProject from "./Pages/MonovougeProject";
import LookplexProject from "./Pages/LookplexProject";
import CuddlycolaProject from "./Pages/CuddlycolaProject";
import CodetechProject from "./Pages/CodetechProject";
import CustomProject from "./Pages/CustomProject";
import Successemailpage from "./Pages/Successemailpage";
import HealthomaxMobileProject from "./Pages/HealthomaxMobileProject";
import HealthomaxWebProject from "./Pages/HealthomaxWebProject";
import NarayanaHomeProject from "./Pages/NarayanaHomeProject";
import SmartcarePatientProject from "./Pages/SmartcarePatientProject";
import SmartcareDoctorProject from "./Pages/SmartcareDoctorProject";
import SmartcareProject from "./Pages/SmartcareProject";
import SmartcareClinicManagementProject from "./Pages/SmartcareClinicManagementProject";
import GardeningProject from "./Pages/GardeningProject";
import EcommerceWebsiteProject from "./Pages/EcommerceWebsiteProject";
import ContentManagementProject from "./Pages/ContentManagementProject";
import SEOPage from "./Components/SEOPage";
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet';
import AiMl from "./Pages/AiMl";
import Aboutus1 from "./Pages/Aboutus1";
import LandingPage from "./Pages/LandingPage";

class App extends Component {
    render() {
        return (
            <HelmetProvider>
                <Helmet>
        {/* Google Tag Manager */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5RLK86MC');`}
        </script>
        {/* End Google Tag Manager */}
      </Helmet>

            <Router>
                <div className="App">
                   
                    <Routes>
                        <Route
                            path="/"
                            element={<Homepage1 />}
                        ></Route>
                        {/* <Route
                            path="/about-us"
                            element={<Aboutus />}
                        ></Route> */}
                        <Route
                            path="/about-us"
                            element={<Aboutus1/>}
                        ></Route>
                        <Route
                            path="/contact-us"
                            element={<Contactus />}
                        ></Route>
                         <Route
                            path="/service/website-design-development-solutions"
                            element={<Wevdev />}
                        ></Route>
                         <Route
                         
                            path="/services/performance-marketing"
                            element={<Performancemarketing/>}
                        ></Route>
                         <Route
                            path="/services/graphic-desinging"
                            element={<Graphicdesigning/>}
                        ></Route>
                        <Route
                            path="/services/digital-marketing"
                            element={<Digitalmarketing/>}
                        ></Route>
                        <Route
                            path="/service/mobile-app-design-development-solutions"
                            element={<Mobiledev/>}
                        ></Route>
                        <Route
                            path="/service/social-media-marketing"
                            element={<Socialmedia/>}
                        ></Route>
                        <Route
                            path="/service/search-engine-optimization-seo"
                            element={<Seo/>}
                        ></Route>
                         <Route
                            path="/Privacypolicy"
                            element={<Privacypolicy/>}
                        ></Route>
                         <Route
                            path="/Termscondition"
                            element={<Termscondition/>}
                        ></Route>
                        <Route
                            path="/our-team"
                            element={<Teams/>}
                        ></Route>
                        <Route
                            path="/Homepage1"
                            element={<Homepage1/>}
                        ></Route>
                         <Route
                            path="/our-completed-projects"
                            element={<ProjectScreen/>}
                        ></Route>
                         <Route
                            path="/our-completed-projects/monovouge"
                            element={<MonovougeProject/>}
                        ></Route>
                         <Route
                            path="/our-completed-projects/lookplex"
                            element={<LookplexProject/>}
                        ></Route>
                         <Route
                            path="/our-completed-projects/cuddly-cola"
                            element={<CuddlycolaProject/>}
                        ></Route>
                         <Route
                            path="/our-completed-projects/codetech"
                            element={<CodetechProject/>}
                        ></Route>
                         <Route
                            path="/service/custom-website-design-development-solutions"
                            element={<CustomProject/>}
                        ></Route>
                         <Route
                            path="/Successemailpage"
                            element={<Successemailpage/>}
                        ></Route>
                         <Route
                            path="/our-completed-projects/healthomax-mobile-app"
                            element={<HealthomaxMobileProject/>}
                        ></Route>
                         <Route
                            path="/our-completed-projects/healthomax-website"
                            element={<HealthomaxWebProject/>}
                        ></Route>
                         <Route
                            path="/our-completed-projects/narayana-homes"
                            element={<NarayanaHomeProject/>}
                        ></Route>
                         <Route
                            path="/our-completed-projects/smartcare-mobile-app-for-ios-andriod"
                            element={<SmartcarePatientProject/>}
                        ></Route>
                         <Route
                            path="/our-completed-projects/clinic-management-mobile-app"
                            element={<SmartcareDoctorProject/>}
                        ></Route>
                         <Route
                            path="/our-completed-projects/smartcare"
                            element={<SmartcareProject/>}
                        ></Route>
                         <Route
                            path="/our-completed-projects/saas-based-clinic-management-platform"
                            element={<SmartcareClinicManagementProject/>}
                        ></Route>
                        <Route
                            path="/our-completed-projects/gardening"
                            element={<GardeningProject/>}
                        ></Route>
                          <Route
                            path="/service/ecommerce-website-design-development-solutions"
                            element={<EcommerceWebsiteProject/>}
                        ></Route>
                         <Route
                            path="/service/cms-website-design-development-solutions"
                            element={<ContentManagementProject/>}
                        ></Route>
                        <Route
                            path="/ai-ml"
                            element={<AiMl/>}
                        ></Route>
                          <Route
                            path="/website-development-services"
                            element={<LandingPage/>}
                        ></Route>
                        
                    <Route path="/" element={<SEOPage />} />
                  
                    </Routes>
                </div>
            </Router>
            </HelmetProvider>
           
        );
    }
}

export default App;

