import Chatbox from "../Components/Chatbox";
import Footer from "../Components/footer";
// import Form from "../Components/Form";
import Header from "../Components/Header";
import SEOPage from "../Components/SEOPage";
// import Subheader from "../Components/Subheader";
import React from "react";
const EcommerceWebsiteProject = () => {

    // ================= Subheader hide and show start ========================

  //   const [isScrollingDown, setIsScrollingDown] = useState(false);
  //   let lastScrollTop = 0;
 
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
 
  //     if (scrollTop > lastScrollTop) {
  //       // Scrolling down, hide the header
  //       setIsScrollingDown(true);
  //     } else {
  //       // Scrolling up, show the header
  //       setIsScrollingDown(false);
  //     }
 
  //     lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // Prevent negative scrolling
  //   };
 
  //   window.addEventListener('scroll', handleScroll);
 
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

    // ================= Subheader hide and show End ========================

//   const headings = [


//     {
//       head:'E Commerce Website',urllink:'/service/ecommerce-website-design-development-solutions'
//       },
//     {
//         head:'Mobile App Development',urllink:'/service/mobile-app-design-development-solutions'
//         },
//     {
//       head:'Web Development',urllink:'/service/website-design-development-solutions'
//       },
//     {
//       head:'Custom Website',urllink:'/service/custom-website-design-development-solutions'
//       },
//     {
//       head:'Graphic Designing',urllink:'/services/graphic-desinging'
//       },
//     {
//       head:'Digital Marketing',urllink:'/services/digital-marketing'
//       },
//     {
//         head:'SEO',urllink:'/service/search-engine-optimization-seo'
//    },
//     {
//         head:'Social Media Marketing',urllink:'/service/social-media-marketing'
//    },
//    {
//     head:'Performance Marketing',urllink:'/services/performance-marketing'
// },
// {
// head:'CMS Website',urllink:'/service/cms-website-design-development-solutions'
// },

//   ];

    const serviceSchemaData8 = {

 "@context": "https://schema.org",
 "@type": "Service",
 "serviceType": "Ecommerce Website Design and Development Solutions",
 "provider": {
 "@type": "Organization",
 "name": "Datametricks Consulting Pvt Ltd",
 "url": "https://www.datametricks.com/",
 "logo": "https://d3vqz9qyn2r1xe.cloudfront.net/imges/comlogo.svg",
 "contactPoint": {
 "@type": "ContactPoint",
 "telephone": "+91 7303946657",
 "contactType": "Customer Service",
 "email": "contact@datametricks.com"
 }
 },
 "description": "Datametricks offers custom ecommerce website design and development solutions, delivering secure, scalable, and user-friendly online stores to help businesses succeed.",
 "areaServed": {
 "@type": "Place",
 "name": "India"
 }
};
  
const ecomimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/ecomimg1.svg";
const ecomimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/ecomimg2.svg";
const ecomimg3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/ecomimg3.svg";
const ecomimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/ecomimg4.svg";
// const customimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/customimg4.svg";
const customimg5 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/customimg5.svg";
const customimg6 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/customimg6.svg";
const customimg7 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/customimg7.svg";
const customimg8 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/customimg8.svg";
const customimg9 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/customimg9.svg";
const customicon1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/customicon3.svg";
const customicon2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/customicon1.svg";
const customicon3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/customicon2.svg";
const sec5dot1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot1.png";
const sec5dot2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot2.png";
const stagesbg = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/stagesbg.png";
const sec5dot3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot3.png";
const sec5dot4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot4.png";

    return (

        <div >
               <SEOPage 
                title="Ecommerce Website Design & Development Solutions | Datametricks"
                description="Datametricks specializes in ecommerce website design and development solutions,
delivering custom online stores with user-friendly designs, secure payment gateways, and
seamless experiences."
                canonicalUrl="https://www.datametricks.com/service/ecommerce-website-design-development-solutions"/>
        <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(serviceSchemaData8) }} />
            
            <div style={{backgroundColor:'rgba(255, 242, 242, 1)'}}>

           
            <div>
                <Header/>
            </div>

     {/* <section className='d-none d-lg-block d-md-block d-sm-none pt-1  '>
      <div className={`header-wrapper ${isScrollingDown ? 'hide' : 'show'}`}>
        <Subheader  headings={headings}/>
        </div>
      </section> */}

            <section>
                <div className="container" style={{marginTop:'5%'}} >
                    <div className="row pt-4">
                        <div className="col-lg-4 ">
                            <div className="pt-20">
                            <a href="https://monovouge.datametricksdigital.in/"> <img src={ecomimg1} alt="" className="img-fluid pt-20" /></a>


                            </div>
                        </div>
                        <div className="col-lg-4">
                            
                        </div>
                        <div className="col-lg-4 text-end">
                     <a href="https://monovouge.datametricksdigital.in/"> <img src={ecomimg2} alt="" className="img-fluid pt-20" /></a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center textcenter">


                            <p className="fs-50 fw-800 plus font-23 mb-0 pt-20" style={{color:'rgba(26, 26, 26, 1)'}}>Transforming Our Wishlist  </p>
                            <p className="fs-50 fw-800 plus font-23 mb-0" style={{color:'rgba(26, 26, 26, 1)'}}>into Reality!</p>
                            <p className="fs-17 fw-400 plus mb-0 font-18 " style={{color:'rgba(34, 34, 34, 0.6)'}}>Innovative E-commerce Solutions for Modern Merchants.  </p>

                          
                            {/* <p className="fs-17 fw-400 plus font-18" style={{color:'rgba(34, 34, 34, 0.6)'}}>that’s 100% unique to you.</p> */}



                        </div>
                    </div>

                    <div className="row pb-4">
                        <div className="col-lg-4 textstart1 text-lg-start  ">
                         <a href="https://monovouge.datametricksdigital.in/">  <img src={ecomimg3} alt="" className="img-fluid pt-10 " /></a>
                        </div>
                        <div className="col-lg-4 text-center">
                            <a href="Contactus"> <button type="button" className="btn   btn-sm pb-0  pt-2 mt-4" style={{backgroundColor:'rgba(0, 110, 205, 1)',boxShadow:'none',border:'none',outline:'none',borderRadius:'42px',paddingLeft:'50px',paddingRight:'50px'}}><p className="fs-19 fw-600 plus text-white font-18" >Contact Us</p></button></a>

                        </div>

                        <div className="col-lg-4 text-lg-end pt-20 textstart1">
                        <a href="https://monovouge.datametricksdigital.in/"><img src={ecomimg4} alt="" className="img-fluid" /></a>
                        </div>
                    </div>

                </div>

            </section>

            </div>

            <section >
                <div className="p-lg-5 padding1" style={{backgroundColor:'rgba(255, 236, 236, 1)'}}>
                    <div className="text-center">


                        {/* <p className="fs-22 fw-500 plus font-23">E-Commerce Solutions?</p> */}
                        <p className="fs-35 fw-700 plus font-23">Crafting Seamless Shopping Experiences!</p>
                        <p className="fs-17 plus font-18" style={{color:'rgba(34, 34, 34, 0.6)'}}>Our comprehensive e-commerce solutions are designed to help you establish and elevate your online store, ensuring a seamless shopping experience for your customers.<br />Let us help you create a powerful online store that drives sales and fosters customer loyalty.</p>
                       
                      

  
                    </div>
                </div>

            </section>
            {/* <section >
                <div className="mt-lg-4 pt-20" >
                    <p className="fs-36 fw-700 plus text-center mb-0 font-23">Details About Our Development </p>
                    <p className="fs-36 fw-700 plus text-center font-23"> Workflow</p>

                </div>
                <div className="container mt-lg-5 mt0">
                    <div className="row justify-content-center">
                        <div className="col-lg-3 text-center">
                            <p className="fs-18 fw-700 plus mb-0 inter"style={{color:'rgba(28, 201, 176, 1)'}}>Tittle Here</p>
                            <p className="fw-400 fs-12 plus inter" style={{color:'rgba(150, 150, 150, 1)'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusan-
                            tium doloremque</p>
                        </div>
                        <div className="col-lg-3 text-center">
                            <p className="fs-18 fw-700 plus mb-0 inter"style={{color:'rgba(57, 109, 233, 1)'}}>Tittle Here</p>
                            <p className="fw-400 fs-12 plus inter" style={{color:'rgba(150, 150, 150, 1)'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusan-
                            tium doloremque</p>
                        </div>
                        <div className="col-lg-3 text-center">
                            <p className="fs-18 fw-700 plus mb-0 inter"style={{color:'rgba(120, 78, 212, 1)'}}>Tittle Here</p>
                            <p className="fw-400 fs-12 plus inter" style={{color:'rgba(150, 150, 150, 1)'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusan-
                            tium doloremque</p>
                        </div>

                    </div>
                    <div className="row text-center mt-2">
                        <div className="col-lg-12">
                            <img src={customimg4} alt="" className="img-fluid" />
                        </div>
                    </div>
                    <div className="row justify-content-center mt-3 ">
                        <div className="col-lg-3 text-center  " >
                            <p className="fs-18 fw-700 plus mb-0 inter"style={{color:'rgba(31, 185, 207, 1)'}}>Tittle Here</p>
                            <p className="fw-400 fs-12 plus inter" style={{color:'rgba(150, 150, 150, 1)'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusan-
                            tium doloremque</p>
                        </div>
                        <div className="col-lg-3 text-center ps-5" >
                            <p className="fs-18 fw-700 plus mb-0 inter"style={{color:'rgba(206, 72, 234, 1)'}}>Tittle Here</p>
                            <p className="fw-400 fs-12 plus inter" style={{color:'rgba(150, 150, 150, 1)'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusan-
                            tium doloremque</p>
                        </div>
                    </div>
                </div>
            </section> */}

            
<section>

<div className="mt-4">


        <p className="fs-36 fw-700 plus text-center mb-0 font-23">Details About Our Development  </p>

        <p className="fs-36 fw-700 plus text-center font-23"> Workflow</p>

 </div>
 <div className="container mt-lg-5 ">
<div className="row ms-lg-5">
  <div className="col-lg-4 ">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot1} alt=""  />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">
          1. Discovery and Planning
        </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
         Understanding your goals, target audience, and requirements to  lay a solid foundation for the project.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot2} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">3. Development </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Transforming designs into functional <br /> websites with
          clean code and seamless functionality.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot1} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">5. Launch</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Deploying the website to the live <br /> environment and
          making it accessible to your audience.
        </p>
      </div>
    </div>
  </div>
</div>
</div>
<div className="container">

<div className="col-lg-12 ms-lg-5">
<img className="img-fluid" src={stagesbg} alt="" />
</div>
</div>
<div className="container pt-4 ">
<div className="row ms-lg-5">
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot3} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">2. Design</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Bringing your vision to life with captivating visuals and
          intuitive user interfaces.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot4} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">4. Testing </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Thoroughly checking every aspect of the website to ensure
          it meets quality standards and performs flawlessly.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot3} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">6. Beyond</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Providing ongoing support, maintenance, and updates to
          ensure your website remains optimized and up to date.
        </p>
      </div>
    </div>
  </div>
</div>



</div>
    
</section>

            <section className="mt-lg-4 mt-10">
               <div>
                    <p className="fs-36 fw-700 plus text-center mb-0 font-23">Information on Tailored Services </p>
                    <p className="fs-36 fw-700 plus text-center font-23"> and Unique Offerings</p>

               </div>
               <div className="container mt-5">
                <div className="row justify-content-center textcenter">
                    <div className="col-lg-3 ">
                        <div className="p-4 h-100" style={{border:'0.73px solid rgba(197, 197, 197, 1)', borderRadius:'13px'}}>
                        <img src={customicon1} alt="" />
                        <p className="fs-20 fw-700 plus pt-4">High Performance – Standard</p>
                        <p className="fs-14 plus " style={{color:'rgba(0, 0, 0, 0.5)'}}>We only design polished WordPress websites that lend your business credibility and trust with visitors. All SEO-ready and mobile-friendly, with a drag-n-drop builder so you can make changes easily.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 mt-10">
                        <div className="p-4 h-100" style={{border:'0.73px solid rgba(197, 197, 197, 1)', borderRadius:'13px'}}>
                        <img src={customicon3} alt="" />
                        <p className="fs-20 fw-700 plus pt-4">Drag-n-Drop for Easy Changes</p>
                        <p className="fs-14 plus " style={{color:'rgba(0, 0, 0, 0.5)'}}>We build your website with an intuitive visual editor, so managing your website content is easy. Make changes to your existing text and images, and even build new pages without any coding required.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 mt-10">
                        <div className="p-4 h-100" style={{border:'0.73px solid rgba(197, 197, 197, 1)', borderRadius:'13px'}}>
                        <img src={customicon2} alt="" />
                        <p className="fs-20 fw-700 plus pt-4">Future-Proof with WordPress®</p>
                        <p className="fs-14 plus " style={{color:'rgba(0, 0, 0, 0.5)'}}>Chosen by over 80% of all websites in the world, WordPress is the standard in web software. It’s continuously improved and made more secure, it’s SEO-ready = day one, and it’s crazy extensible thanks to practically infinite 3rd-party plugins you can add as needed.</p>
                        </div>
                    </div>
                </div>
               </div>
            </section>

            <section className="mt-lg-4 mt-10 ">
               <div>
                    <p className="fs-36 fw-700 plus text-center mb-0 font-23">Details About The Tools and  </p>
                    <p className="fs-36 fw-700 plus text-center font-23"> Technologies Utilized</p>

               </div>
               <div className="container mt-lg-5 mt-30">
                    <img src={customimg5} alt="" className="img-fluid" />
               </div>
              
            </section>

            <section className="mt-lg-5 mt-30">
                <div>
                    <img src={customimg6} alt="" className="img-fluid" />
                </div>

            </section>

            <section className="mt-lg-5 mt-30">
               <div>


                    <p className="fs-36 fw-700 plus text-center mb-0 font-23">Designed for you and built by us    </p>
                    <p className="fs-36 fw-700 plus text-center font-23"> Explore Our Work     </p>



               </div>
               <div className="container mt-lg-5 mt-30">
                    <div className="row justify-content-center textcenter">
                        <div className="col-lg-3">
                             <img src={customimg7} alt="" className="img-fluid " />
                        </div>
                        <div className="col-lg-3">
                             <img src={customimg8} alt="" className="img-fluid mt-10" />
                        </div>
                        <div className="col-lg-3">
                             <img src={customimg9} alt="" className="img-fluid mt-10" />
                        </div>
                    </div>
               </div>
              
            </section>

        
       
{/* <section >
        <div className='newhome-bg1 resnewhome-bg1 d-flex align-items-center'>  
  <div className='container p-30'>
    <div className='row justify-content-center align-items-center'>
      <div className='col-lg-7 col-md-6 col-12 textcenter '>
          <h2 className='fs-50 fw-700 plus text-white font-25 mt-35'>Transform Your Digital Presence</h2>
          <p className='fs-18 fw-400 plus font-17' style={{color:'rgba(192, 199, 206, 1)'}}>Ready to transform your digital presence? <br /><br />Fill out the form below to get a free consultation or a  quote for our top-notch website development and digital marketing services. Let's take your  business to new heights together!</p>
      </div>
      <div className='col-lg-4 col-md-6 col-12'>
      <Form/>
        </div>
    </div>
  </div>
  </div>
  
</section> */}

<section>
  <div>
    <Chatbox/>
  </div>
</section>

            <div className="mt-5">
                <Footer/>
            </div>


        </div>

    );
};
export default EcommerceWebsiteProject;