import Chatbox from "../Components/Chatbox";
import Header from "../Components/Header";
import Footer from "../Components/footer";
import SEOPage from "../Components/SEOPage";
// import Subheader from "../Components/Subheader";
import React from 'react';


const ProjectScreen = () => {

    // ================= Subheader hide and show start ========================

  //   const [isScrollingDown, setIsScrollingDown] = useState(false);
  //   let lastScrollTop = 0;
 
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
 
  //     if (scrollTop > lastScrollTop) {
  //       // Scrolling down, hide the header
  //       setIsScrollingDown(true);
  //     } else {
  //       // Scrolling up, show the header
  //       setIsScrollingDown(false);
  //     }
 
  //     lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // Prevent negative scrolling
  //   };
 
  //   window.addEventListener('scroll', handleScroll);
 
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

    // ================= Subheader hide and show End ========================


//   const headings = [

//     {
//       head:'Our Projects',urllink:'/our-completed-projects'
//       },
//     {
//       head:'Web Development',urllink:'/service/website-design-development-solutions'
//       },
//     {
//       head:'Custom Website',urllink:'/service/custom-website-design-development-solutions'
//       },
//     {
//       head:'Graphic Designing',urllink:'/services/graphic-desinging'
//       },
//     {
//       head:'Digital Marketing',urllink:'/services/digital-marketing'
//       },
//     {
//         head:'SEO',urllink:'/service/search-engine-optimization-seo'
//    },
//     {
//         head:'Social Media Marketing',urllink:'/service/social-media-marketing'
//    },
//    {
//     head:'Performance Marketing',urllink:'/services/performance-marketing'
// },
// {
// head:'Mobile App Development',urllink:'/service/mobile-app-design-development-solutions'
// },
// {
// head:'E Commerce Website',urllink:'/service/ecommerce-website-design-development-solutions'
// },
// {
// head:'CMS Website',urllink:'/service/cms-website-design-development-solutions'
// },

//   ];


const projectimg1 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img1.svg';
const projectimg2 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img2.svg';
const projectimg3 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img3.svg';
const projectimg4 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img4.svg';
const projectimg5 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img5.svg';
const projectimg6 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img6.svg';
const projectimg7 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img7.svg';
const projectimg8 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img8.svg';
const projectimg9 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img9.svg';
const projectimg10 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img10.svg';
const projectimg11 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/healthomaxappimg-C.png';
const projectimg12 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/patientappimg-B.png';
const projectimg13 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/docappimg-A.png';

    const projectdata=[
        { 
            heading:"Monovogue",
            title:"We developed a dynamic and visually appealing e-commerce platform that enhances the shopping experience. The site includes secure payment gateways, intuitive navigation, and a responsive design for seamless browsing on all devices.",
            bgcolor:"white",
            border:"1px solid rgba(175, 80, 17, 1)",
            img:projectimg8,
            url:"/our-completed-projects/monovouge"
        },
        { 
            heading:"Lookplex",
            title:"Salon at Home Services Website For a salon at home services provider, we designed a sleek and modern website with an easy booking feature. The site allows customers to book appointments effortlessly and explore various beauty services offered.",
            bgcolor:"white",
            border:"1px solid rgba(210, 100, 140, 1)",

            img:projectimg1,
             url:"/our-completed-projects/lookplex"

        },
        { 
            heading:"Cuddly koala",
            title:"We created an informative and professional website for a doctor and lactation consultant. The site features comprehensive service information, patient resources, and an intuitive appointment booking system.",
            bgcolor:"white",
            border:"1px solid rgba(100, 100, 100, 1)",

            img:projectimg2,
             url:"/our-completed-projects/cuddly-cola"

        },
        { 
            heading:"3D Gardening",
            title:"We designed a vibrant and user-friendly website for a client providing gardening services. The site show cases their expertise, offers detailed service descriptions, and includes an easy-to-use contact form for customer inquiries.",
            bgcolor:"white",
            border:"1px solid rgba(52, 142, 56, 1)",

            img:projectimg3,
             url:"/our-completed-projects/gardening"

        },
        { 
            heading:"Codetech",
            title:"We designed an intuitive and engaging UI for Codetech, a website development company. The design focuses on enhancing user experience and showcasing their services effectively.",
            bgcolor:"white",
            border:"1px solid rgba(33, 37, 41, 1)",

            img:projectimg4,
             url:"/our-completed-projects/codetech"

        },
        { 
            heading:"Narayana Home Services",
            title:"We developed a comprehensive website for Narayana Home, a home worker providers service company. The site allows users to easily hire cooks, maids, chefs, and watchmen, ensuring a seamless hiring process.",
            bgcolor:"white",
            border:"1px solid rgba(0, 170, 228, 1)",

            img:projectimg5,
             url:"/our-completed-projects/narayana-homes"

        },
        { 
            heading:"HealthOMax",
            title:"HealthOMax is a social media platform for medical professionals. We developed a website where doctors can connect, share case studies, journals, and news, and patients can ask queries to doctors, fostering a collaborative medical community.",
            bgcolor:"white",
            border:"1px solid rgba(0, 51, 164, 1)",

            img:projectimg6,
             url:"/our-completed-projects/healthomax-website"

        },
        { 
            heading:"SmartCare",
            title:"We created a healthcare platform, SmartCare, that enables patients to easily book appointments, find doctors, store medical records, and save vital health information, streamlining their healthcare experience.",
            bgcolor:"white",
            border:"1px solid rgba(17, 0, 158, 1)",

            img:projectimg7,
             url:"/our-completed-projects/smartcare"

        },
        { 
            heading:"HealthOMax App",
            title:"We developed the HealthOMax mobile application for both Android and iOS platforms. The app offers the same features as the website, allowing medical professionals to connect and collaborate, and patients to interact with doctors on the go.",
            bgcolor:"white",
            border:"1px solid rgba(0, 51, 164, 1)",

            img:projectimg11,
             url:"/our-completed-projects/healthomax-mobile-app"

        },
        { 
            heading:"SmartCare Clinic Management",
            title:"SmartCare is a SAAS-based clinic management software for doctors. It includes features like consultation management, prescription generation, appointment booking, follow-ups, and comprehensive EMR (Electronic Medical Records) capabilities.",
            bgcolor:"white",
            border:"1px solid rgba(17, 0, 158, 1)",

            img:projectimg10,
             url:"/our-completed-projects/saas-based-clinic-management-platform"

        },
        { 
            heading:"HealthOMax Web",
            title:"An extremely integrated platform of medical professionals and patients. Our website provides access to the same power as of our mobile application available anywhere, anytime, on any device.",
            bgcolor:"white",
            border:"1px solid rgba(0, 51, 164, 1)",

            img:projectimg9,
             url:"/our-completed-projects/healthomax-website"

        },
        { 
            heading:"SmartCare Patient App",
            title:"We developed the SmartCare patient mobile application for iOS and Android, providing patients with features such as appointment booking, doctor search, record storage, and vital tracking, all in one convenient app.",
            bgcolor:"white",
            border:"1px solid rgba(17, 0, 158, 1)",

            img:projectimg12,
             url:"/our-completed-projects/smartcare-mobile-app-for-ios-andriod"

        },
        { 
            heading:"SmartCare Doctor App",
            title:"The SmartCare Doctors App, available on both Android and iOS, offers all the functionalities of the EMR system, allowing doctors to manage consultations, generate prescriptions, book appointments, and handle follow-ups efficiently  their mobile devices.",
            bgcolor:"white",
            border:"1px solid rgba(17, 0, 158, 1)",

            img:projectimg13,
             url:"/our-completed-projects/clinic-management-mobile-app"

        },


    ]

    return (
        
      <div>
         <SEOPage 
                title="Our Completed Projects | Success Stories by Datametricks Consulting Pvt Ltd"
                description="Explore Datametricks' portfolio of completed projects, showcasing our expertise in website
design, development, mobile apps, SEO, and digital marketing. See how we've helped
businesses succeed online."
                canonicalUrl="https://www.datametricks.com/our-completed-projects"
            />

                 {/* section 1 */}
          <section>
               <div className="">
                      <Header />
               </div>
            
           </section>

           {/* <section className='d-none d-lg-block d-md-block d-sm-none pt-1  '>
      <div className={`header-wrapper ${isScrollingDown ? 'hide' : 'show'}`}>
        <Subheader  headings={headings}/>
        </div>
      </section> */}


        <section style={{marginTop:'100px'}} className="mt-50">

        <div className="container textcenter ">
                <h2 className="fw-300 fs-40 plus font-23 " style={{color:'rgba(15, 15, 15, 1)'}}>Our Best <span className="fw-600 fs-40 plus font-23" style={{color:'rgba(0, 83, 154, 1)'}}>Projects</span></h2>
                <p className="fs-18 fw-400 plus font-17 " style={{color:'rgba(19, 19, 19, 0.7)'}}>All projects are unique. We start your strengths and goals to develop a digital strategy that increases your online sales.</p>
        </div>


        <div className="container projectcontainer mt-5">
  <div className="row">
  {projectdata?.map((item1, index) => (
    <div className="col-lg-6 mb-5" eventKey={index}>
    <a href={item1.url} style={{textDecoration:'none'}}><div className="projectresponsive-box py-4 px-5 " style={{backgroundColor:item1.bgcolor,border:item1.border}}>
      <div className="projectimage-container">
          <img src={item1.img} alt="" className="projectresponsive-img img-fluid hover1" />
        </div>
        <h1 className="projecttitle">{item1.heading}</h1>
        <p className="projectdescription">
         {item1.title}
        </p>
       
      </div></a>
    </div>
    )  )}
  </div>
</div>    
        </section>


        <section>
  <div>
    <Chatbox/>
  </div>
</section>
             <section >
              <div className="mt-5" >
                     <Footer />
                     </div>
           </section>

     </div>
  );
};
export default ProjectScreen;




