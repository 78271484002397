import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import '../Components/webDevSwiper.css';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules';

export default function App() {
  return (
    <>
      <Swiper
        pagination={true}
        modules={[Pagination, Autoplay]}
        autoplay={{
          delay: 3000, // Set delay in milliseconds
          disableOnInteraction: false, // Autoplay won't stop on user interaction
        }}
        className="mySwiper"
      >
        <div className="px-4">
          <SwiperSlide>
            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/swiper1.png" alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/swiper2.png" alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/swiper3.png" alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/swiper4.png" alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/swiper5.png" alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/swiper6.png" alt="" className="img-fluid" />
          </SwiperSlide>
        </div>
      </Swiper>
    </>
  );
}
