import React from "react";

const CompanyLogoSlider = () => {

  const logos = [
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sliderlogo1.svg", 
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sliderlogo2.svg",
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sliderlogo3.svg",
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sliderlogo4.svg",
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sliderlogo5.svg",
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sliderlogo6.svg",
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sliderlogo7.svg",
  ];

  return (
    <div className="logo-slider-container123">
      <div className="logo-slider123">
        {logos.map((logo, index) => (
          <img src={logo} alt={`Logo ${index + 1}`} key={index} className="logo123" />
        ))}
        
        {/* Duplicate for seamless animation */}
        {logos.map((logo, index) => (
          <img src={logo} alt={`Logo Duplicate ${index + 1}`} key={`duplicate-${index}`} className="logo123" />
        ))}

           {/* Duplicate for seamless animation */}
           {logos.map((logo, index) => (
          <img src={logo} alt={`Logo Duplicate ${index + 1}`} key={`duplicate-${index}`} className="logo123" />
        ))}

           {/* Duplicate for seamless animation */}
           {logos.map((logo, index) => (
          <img src={logo} alt={`Logo Duplicate ${index + 1}`} key={`duplicate-${index}`} className="logo123" />
        ))}


      </div>
    </div>
  );
};

export default CompanyLogoSlider;
