import React from 'react';
import Header from "../Components/Header";
import Footer from "../Components/footer";
// import Bigcontact from "../Components/Bigcontact";
// import Form from "../Components/Form";
// import Minicontact from "../Components/Minicontact";
// import Faq from "../Components/Faq";
// import WebdevCard from "../Components/WebdevCard";
// import Testimonial1 from "../Components/Testimonial1";
import Chatbox from "../Components/Chatbox";
import SEOPage from "../Components/SEOPage";
// import Blog1 from "../Components/Blog1";
import { Helmet } from "react-helmet";
// import Subheader from "../Components/Subheader";
import Faqaccordian from '../Components/Faqaccordian';
import Logoslider from '../Components/logoslider';
import WebDevSwiper from '../Components/WebDevSwiper';
import WebDevShopifySlider from '../Components/WebDevShopifySlider';
import WebDevForm from '../Components/WebDevForm';

const verimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/versionimg1.svg";
const verimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/versionimg2.svg";
const verimg3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/versionimg3.svg";
const verimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/versionimg4.svg";
const verimg5 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/versionimg5.svg";
const verimg6 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/versionimg6.svg";
const custompageimg = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/custompageimg.svg";



function Webdev() {

  const projectimg1 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img1.svg';
const projectimg2 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img2.svg';
const projectimg3 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img3.svg';
// const projectimg4 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img4.svg';
// const projectimg5 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img5.svg';
// const projectimg6 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img6.svg';
const projectimg7 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img7.svg';
const projectimg8 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img8.svg';
const projectimg9 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img9.svg';
// const projectimg10 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img10.svg';
// const projectimg11 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/healthomaxappimg-C.png';
// const projectimg12 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/patientappimg-B.png';
// const projectimg13 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/docappimg-A.png';

  // ================= Subheader hide and show start ========================

  // const [isScrollingDown, setIsScrollingDown] = useState(false);
  //   let lastScrollTop = 0;
 
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
 
  //     if (scrollTop > lastScrollTop) {
  //       // Scrolling down, hide the header
  //       setIsScrollingDown(true);
  //     } else {
  //       // Scrolling up, show the header
  //       setIsScrollingDown(false);
  //     }
 
  //     lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // Prevent negative scrolling
  //   };
 
  //   window.addEventListener('scroll', handleScroll);
 
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

    // ================= Subheader hide and show End ========================


//   const headings = [


//     {
//       head:'Web Development',urllink:'/service/website-design-development-solutions'
//       },
//     {
//       head:'Custom Website',urllink:'/service/custom-website-design-development-solutions'
//       },
//     {
//       head:'Graphic Designing',urllink:'/services/graphic-desinging'
//       },
//     {
//       head:'Digital Marketing',urllink:'/services/digital-marketing'
//       },
//     {
//         head:'SEO',urllink:'/service/search-engine-optimization-seo'
//    },
//     {
//         head:'Social Media Marketing',urllink:'/service/social-media-marketing'
//    },
//    {
//     head:'Performance Marketing',urllink:'/services/performance-marketing'
// },
// {
// head:'Mobile App Development',urllink:'/service/mobile-app-design-development-solutions'
// },
// {
// head:'E Commerce Website',urllink:'/service/ecommerce-website-design-development-solutions'
// },
// {
// head:'CMS Website',urllink:'/service/cms-website-design-development-solutions'
// },

//   ];


  // const [categories, setCategories] = useState([]);

  // useEffect(() => {
  //   fetch('https://blog.datametricks.com/wp-json/wp/v2/categories')
  //     .then((response) => response.json())
  //     .then((data) => setCategories(data.slice(0,3)))
  //     .catch((error) => console.error('Error fetching categories:', error));
  // }, []);

 
  // const categoryId=3;


  // const newwebdev1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newwebdev1.svg";


  const serviceSchemaData1 = 
  [
  {
    "@context": "https://schema.org",
    "@type": "Service",
    "serviceType": "Website Design and Development",
    "provider": {
      "@type": "Organization",
      "name": "DataMetricks Consulting Pvt Ltd",
      "url": "https://www.datametricks.com/",
      "logo": "https://d3vqz9qyn2r1xe.cloudfront.net/imges/comlogo.svg",
      "sameAs": [
        "https://www.facebook.com/people/DataMetricks-Consulting-PvtLtd/61554350043470/",
        "https://www.instagram.com/datametricks_pvt_ltd/",
        "https://x.com/datametricks",
        "https://www.linkedin.com/company/datametricks-consulting-pvt-ltd/"
      ],
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+91 7303946657",
        "contactType": "Customer Service",
        "email": "contact@datametricks.com"
      },
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "A40, ITHUM TOWER, Industrial Area, Sector 62",
        "addressLocality": "Noida",
        "addressRegion": "Uttar Pradesh",
        "postalCode": "201309",
        "addressCountry": "IN"
      }
    },
    "areaServed": {
      "@type": "Place",
      "name": "India"
    },
    "description": "DataMetricks provides professional website design and development services, delivering custom web solutions for businesses of all sizes. Our services include responsive design, e-commerce development, and CMS integration to help you establish a strong online presence.",
    "offers": {
      "@type": "Offer",
      "url": "https://www.datametricks.com/service/website-design-development",
      "priceCurrency": "INR",
      "eligibleRegion": {
        "@type": "Place",
        "name": "India"
      },
      "availability": "https://schema.org/InStock",
      "validThrough": "2024-12-31"
    }
  }
];


const webprojectdata=[
  { 
      heading:"Monovogue",
      title:"We developed a dynamic and visually appealing e-commerce platform that enhances the shopping experience with includes secure payment gateways.",
      bgcolor:"rgba(175, 80, 17, 1)",
      img:projectimg8,
      url:"/our-completed-projects/monovouge"
  },
  { 
      heading:"Lookplex",
      title:"Salon at Home Services Website For a salon services provider, we designed a sleek and modern website with an easy booking feature. ",
      bgcolor:"rgba(210, 100, 140, 1)",
      img:projectimg1,
       url:"/our-completed-projects/lookplex"

  },

  { 
      heading:"3D Gardening",
      title:"We designed a vibrant and user-friendly website for a client providing gardening services. easy-to-use contact form for customer inquiries.",
      bgcolor:"rgba(52, 142, 56, 1)",
      img:projectimg3,
       url:"/our-completed-projects/gardening"

  },
  { 
    heading:"Cuddly koala",
    title:"We created an informative website for a doctor and lactation consultant, it provides comprehensive appointment booking system.",
    bgcolor:"rgba(100, 100, 100, 1)",
    img:projectimg2,
     url:"/our-completed-projects/cuddly-cola"

},
  { 
    heading:"Healthomax Web",
    title:"We developed the HealthOmax website to offers allowing medical professionals to connect and collaborate, and patients to interact with doctors on the go.",
    bgcolor:"rgba(0, 51, 164, 1)",
    img:projectimg9,
     url:"/our-completed-projects/healthomax-website"

},
  { 
      heading:"SmartCare",
      title:"We created a healthcare platform, SmartCare, that enables patients to easily book appointments, find doctors, store medical records.",
      bgcolor:"rgba(17, 0, 158, 1)",
      img:projectimg7,
       url:"/our-completed-projects/smartcare"

  }
 
 


];



// const backgroundimg = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec1bg.png";
// const sec4img1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec4img1.png";
const sec5dot1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot1.png";
const sec5dot2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot2.png";
const sec5dot3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot3.png";
const sec5dot4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot4.png";
const stagesbg = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/stagesbg.png";
const newwebdevimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img-1.svg";
const newwebdevimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img-2.svg";
const newwebdevimg3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img-3.svg";
const newwebdevimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img-4.svg";
const newwebdevimg5 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img-5.svg";
const newwebdevimg6 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img-6.svg";
const newwebdevimg7 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img-7.svg";
const newwebdevimg8 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img-8.svg";
const newwebdevimg9 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img9.svg";
const newwebdevimg10 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img10.svg";
// const testimonialimage = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/testimonial-img.png";
// const webnewimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/webnewimg1.png";
// const webnewimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/webnewimg2.png";
// const webnewimg3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/webnewimg3.png";
// const webnewimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/webnewimg4.png";
// const webnewimg5 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/webnewimg5.png";
// const webnewimg6 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/webnewimg6.png";
// const webnewimg7 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/webnewimg7.png";
// const webnewimg8 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/webnewimg8.png";
// const webnewimg9 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/webnewimg9.png";

  var faqData=[
            {que1:"1. What is web development and why is it important?",ans1:"Web development involves creating and maintaining websites. It includes web design, web publishing, web programming, and database management. A well-developed website enhances a business's online presence, boosts credibility, attracts customers, and drives sales." },
            {que1:"2. How much does it cost to develop a website? ", ans1:"The cost of developing a website can vary greatly depending on factors such as complexity, features, design, and the development company."},
            {que1:"3. How long does it take to build a website? ",ans1:"The time required to build a website depends on its complexity and the specific requirements of the project. A simple website might take a few weeks, while a more complex site with custom features can take several months."},
            {que1:"4. What are the key features of a good website? ",ans1:"A good website should be user-friendly, responsive (works on all devices), fast-loading, secure, SEO-optimized, and visually appealing. It should also have clear navigation, engaging content, and effective call-to-actions. "},
            {que1:"5. Do I need a mobile-friendly website? ",ans1:"Yes, having a mobile-friendly (responsive) website is essential as more people access the internet through mobile devices. A mobile-friendly site improves user experience, boosts SEO rankings, and increases the chances of retaining visitors. "},
            {que1:"6. What is SEO and why is it important for my website? ",ans1:"SEO (Search Engine Optimization) involves optimizing your website to rank higher in search engine results. It is important because higher rankings lead to increased visibility, more traffic, and potentially more customers. "},
            {que1:"7. Can I update my website content by myself? ",ans1:"Yes, if your website is built with a Content Management System (CMS) like WordPress, you can easily update your content without needing technical knowledge. A CMS allows you to add, edit, and delete content as needed. " },
            {que1:"8. What is the difference between web design and web development? ",ans1:"Web design focuses on the visual aspects of a website, including layout, color schemes, and graphics. Web development, on the other hand, involves coding and programming to create the website’s functionality and features. " },
            {que1:"9. Why should I choose DataMetricks for my web development needs? ",ans1:"Datametricks Consulting offers expert web development services tailored to your business needs. We prioritize user experience, use the latest technologies, and provide ongoing support to ensure your website is always optimized and effective. "},
            {que1:"10. What kind of support do you provide after the website is launched? ",ans1:"We offer comprehensive support and maintenance services, including regular updates, security checks, performance optimization, and troubleshooting to ensure your website remains secure and performs at its best. "},    
      ];

  return (
    <div>
        <SEOPage 
                title="Professional Website Design & Development Services in Noida | Custom Web Solutions - DataMetricks"
                description="DataMetricks offers expert website design and development services, delivering custom,
                              responsive, and user-friendly web solutions tailored to your business needs. Enhance your online
                                presence with our web design expertise. Contact us today for a consultation!"
                canonicalUrl="https://www.datametricks.com/service/website-design-development-solutions"  />

        <Helmet>
             <script type="application/ld+json">
                  {JSON.stringify(serviceSchemaData1)}
             </script>
      </Helmet>
     

      <Header />
     
      {/* <section className='d-none d-lg-block d-md-block d-sm-none pt-1  '>
      <div className={`header-wrapper ${isScrollingDown ? 'hide' : 'show'}`}>
        <Subheader  headings={headings}/>
        </div>
      </section> */}


   
      <section className='d-none d-sm-none d-md-block d-lg-block'>
        <div className='mt-35' style={{marginTop:'5%'}}>
          <div className='newwebdevbg'>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-lg-6 d-flex align-items-center'>

             

            <div className='pt-40'>
            <p className='fs-38 fw-800 plus  font-23 ' style={{color:'rgba(255, 255, 255, 1)'}}>Empower Your Business With The Best Website Development Company in Noida</p>
            <p className='fs-20 fw-400 plus   font-18 ' style={{color:'rgba(255, 255, 255, 0.6)'}}>We are the leading web-development company in noida, we are committed to delivering customized solutions that meet your business needs.</p>
            {/* <div style={{textAlign:'center',marginTop:'25px'}}>
        <a href='/contact-us'>    <button type="button" class="btn px-5  pb-2 font-18 getstartedbtn " style={{backgroundColor:'rgba(0, 110, 205, 1)',borderRadius:'50px',color:'white',fontSize:'19px',fontWeight:'500',border:'none',boxShadow:'none',  overflow: 'hidden', position: 'relative',  transition: 'background-color 0.3s ease'}}>
                        <span className="getstarted-text">
                                 Get Started
                        </span>
            </button></a>
            </div> */}
          </div>
          </div>
          <div className='col-lg-1'></div>
          <div className='col-lg-4 '>
          <div className='pt-lg-4'>

          <WebDevForm/>
          </div>
          </div>
              </div>
            </div>

          </div>
      </div>
      </section>


{/* -------Mobile--------- */}
<section className='d-block d-sm-block d-md-none d-lg-none'>
<div className='mob-dev-mo-view'>

    <div className='container text-center' style={{paddingTop:'90px'}}>
    <p className='fs-23 fw-800 plus ' style={{color:'rgba(255, 255, 255, 1)'}}>Empower Your Business with the Best Website Development Company in Noida</p>
    <p className='fs-18 fw-400 plus  mb-0  ' style={{color:'rgba(255, 255, 255, 0.6)'}}>We are the leading web-development company in noida, we are committed to delivering customized solutions that meet your business needs.</p>
    <div className='row'>
        <div className='col-lg-4'>
        <WebDevForm/>
        </div>
    </div>
    </div>

</div>

</section> 

    <section>
        <div>
        <Logoslider/>
        </div>
      </section> 
     
      <section>
      <div className='p-lg-5 p-20 ' style={{backgroundColor:'rgba(232, 245, 255, 1)'}}>
        <div className='text-center'>
          <p className='fw-700 fs-35 plus font-23'>We Build Websites That Grow Your Business </p>
          <p className='fs-18 fw-400 plus font-18 pl0 pr0' style={{color:'rgba(0, 0, 0, 0.6)',paddingLeft:'190px',paddingRight:'190px'}}>We don't just build websites, we create online solutions that drive results. Our deep understanding of diverse business landscapes allows us to craft websites that perfectly align with your unique goals. We combine user-friendly design, cutting-edge technology, and a focus on conversions to deliver a website that elevates your online presence and fuels your success. </p>
          </div>
      </div>

      </section>

      <section className='mt-lg-4 mt-10'>
        <div>
          <p className='fs-35 fw-700 plus text-center d-none d-lg-block d-md-block d-sm-none font-22'>Maximize Your Online Success with Our All-Inclusive<br/>Web Development Services </p>
          <p className='fs-35 fw-700 p-10 plus text-center d-block d-sm-block d-md-none d-lg-none font-22'>Maximize Your Online Success with Our All-Inclusive Web Development Services </p>
        </div>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-10'>
              <div className='d-none d-lg-block d-md-block d-sm-none'>
             <div className='d-flex gap-5 mt-4 '>
                <div >
                  <img src={newwebdevimg1} alt='' className='img-fluid' />
                  <p className='fw-500 fs-17 plus pt-3 font-15' style={{color:'rgba(19, 19, 19, 1)'}}>Website Design & Development</p>
                </div>
                <div >
                  <img src={newwebdevimg2} alt='' className='pt-1 img-fluid' />
                  <p className='fw-500 fs-17 plus pt-3 font-15' style={{color:'rgba(19, 19, 19, 1)'}}>E-Commerce Development</p>
                </div>
                <div >
                  <img src={newwebdevimg3} alt='' className='pt-1 img-fluid'/>
                  <p className='fw-500 fs-17 plus pt-3 font-15' style={{color:'rgba(19, 19, 19, 1)'}}>Content Management System</p>
                </div>
                <div >
                  <img src={newwebdevimg4} alt='' className='pt-1 img-fluid' />
                  <p className='fw-500 fs-17 plus pt-3 font-15' style={{color:'rgba(19, 19, 19, 1)'}}>Website Maintenance & Support</p>
                </div>
                <div  >
                  <img src={newwebdevimg5} alt='' className='pt-1 img-fluid'/>
                  <p className='fw-500 fs-17 plus pt-3 font-15' style={{color:'rgba(19, 19, 19, 1)'}}>Responsive Design </p>
                </div>
                </div>
                </div>

                  {/* For mobile screens */}
      <div className='d-block d-lg-none d-md-none d-sm-block'>
        <div className='row'>
          <div className='col-6 text-center '>
            <img src={newwebdevimg1} alt='' className='img-fluid' style={{ height: '90px', width: '85px' }} />
            <p className='fw-500 fs-18 plus pt-2' style={{ color: 'rgba(19, 19, 19, 1)' }}>Website Design &<br /> Development</p>
          </div>
          <div className='col-6 text-center mt-3'>
            <img src={newwebdevimg2} alt='' className='img-fluid' style={{ height: '78px', width: '78px' }} />
            <p className='fw-500 fs-18 plus pt-2' style={{ color: 'rgba(19, 19, 19, 1)' }}>E-Commerce<br /> Development</p>
          </div>
          <div className='col-6 text-center mt-3'>
            <img src={newwebdevimg3} alt='' className='img-fluid' style={{ height: '75px', width: '75px' }} />
            <p className='fw-500 fs-18 plus pt-2' style={{ color: 'rgba(19, 19, 19, 1)' }}>Content Management<br /> System</p>
          </div>
          <div className='col-6 text-center mt-3'>
            <img src={newwebdevimg4} alt='' className='img-fluid' style={{ height: '75px', width: '75px' }} />
            <p className='fw-500 fs-18 plus pt-2' style={{ color: 'rgba(19, 19, 19, 1)' }}>Website Maintenance<br /> & Support</p>
          </div>
          <div className='col-6 text-center mt-3'>
            <img src={newwebdevimg5} alt='' className='img-fluid' style={{ height: '75px', width: '75px' }} />
            <p className='fw-500 fs-18 plus pt-2' style={{ color: 'rgba(19, 19, 19, 1)' }}>Responsive Design</p>
          </div>
        </div>
      </div>



                 <div className='d-none'>
             <div className='row '>
            
                <div className='col-sm-2 col-12 text-center' >
                  <img src={newwebdevimg1} alt='' className='img-fluid' style={{height:'90px',width:'85px'}} />
                  <p className='fw-500 fs-18 plus  pt-2' style={{color:'rgba(19, 19, 19, 1)'}}>E-Commerce<br/>Development</p>
                </div>

               

                  <div className='col-sm-2 col-12 text-center mt-3'>
                  <img src={newwebdevimg5} alt='' className=' img-fluid ' style={{height:'78px',width:'78px'}} />
                  <p className='fw-500 fs-18 plus   pt-2' style={{color:'rgba(19, 19, 19, 1)'}}>App Design &<br/> Development</p>
                </div>

                <div className='col-sm-2 col-12 text-center mt-3'>
                  <img src={newwebdevimg2} alt='' className=' img-fluid ' style={{height:'78px',width:'78px'}} />
                  <p className='fw-500 fs-18 plus   pt-2' style={{color:'rgba(19, 19, 19, 1)'}}>Website Design &<br/> Development</p>
                </div>

                <div className='col-sm-2 col-12 text-center mt-3' >
                  <img src={newwebdevimg3} alt='' className=' img-fluid'  style={{height:'75px',width:'75px'}}/>
                  <p className='fw-500 fs-18 plus  pt-2' style={{color:'rgba(19, 19, 19, 1)'}}>Content Management<br/>System</p>
                </div>

                <div className='col-sm-2 col-12 text-center mt-3'>
                  <img src={newwebdevimg4} alt='' className=' img-fluid'  style={{height:'75px',width:'75px'}}/>
                  <p className='fw-500 fs-18 plus   pt-2' style={{color:'rgba(19, 19, 19, 1)'}}>Website Maintenance<br/>& Support</p>
                </div>

                <div  className='col-sm-2 col-12  text-center textcenter mt-3'>
                  <img src={newwebdevimg5} alt='' className=' img-fluid pt-1'  style={{height:'75px',width:'75px'}}/>
                  <p className='fw-500 fs-18 plus   pt-2' style={{color:'rgba(19, 19, 19, 1)'}}>Responsive Design </p>
                </div>
                
              
                </div> 
            </div>
          </div>

        </div>
        </div>
      </section>

      <section className='d-none d-sm-none d-lg-block d-md-block'>
    <div className='container'>
        <div className="row justify-content-center mt-lg-5 mt-30" >
            <div className="col-lg-10" >
                <div className="row justify-content-center g-3" >
                    <div className="col-md-6">
                        <div className="d-flex gap-lg-4 gap-14 align-items-center " style={{border:'1px solid rgba(223, 223, 223, 1)',borderRadius:'19px'}}>
                            <div>
                                 <img src={verimg1} className="" alt="" />
                            </div>
                            <div>
                               <h1 className="fw-600 fs-16 plus newfont-20 " style={{color:'rgba(24, 24, 24, 1)'}}>Static Website</h1>
                               <p className="fs-13 lexend fw-400 newfont-13 d-sm-none d-none d-md-block d-lg-block" style={{color:'rgba(0, 0, 0, 0.45)'}}>Create a unique and visually appealing website tailored to your brand. Our custom designs  ensure your site stands out and engages your audience effectively.</p>
                               <p className="fs-13 lexend fw-400 newfont-13 d-sm-block d-block d-md-none d-lg-none" style={{color:'rgba(0, 0, 0, 0.45)'}}>Create a unique and visually appealing website tailored to your brand.</p>

                            </div>
                        </div>

                    </div>
                    <div className="col-md-6">
                        <div className="d-flex gap-lg-4 gap-14 align-items-center " style={{border:'1px solid rgba(223, 223, 223, 1)',borderRadius:'19px'}}>
                            <div>
                                 <img src={verimg2} className="" alt="" />
                            </div>
                            <div>
                               <h1 className="fw-600 fs-16 plus newfont-20 " style={{color:'rgba(24, 24, 24, 1)'}}>Dynamic Website</h1>
                               <p className="fs-13 lexend fw-400 newfont-13 d-sm-none d-none d-md-block d-lg-block" style={{color:'rgba(0, 0, 0, 0.45)'}}>Boost your online sales with our robust e-commerce solutions. We develop secure, scalable, and  user-friendly online stores that enhance the shopping experience for your customers.</p>
                               <p className="fs-13 lexend fw-400 newfont-13 d-sm-block d-block d-md-none d-lg-none" style={{color:'rgba(0, 0, 0, 0.45)'}}>Boost your online sales with our robust web solutions. We develop dynamics websites.</p>

                            </div>
                        </div>

                    </div>
                    <div className="col-md-6 ">
                        <div className="d-flex gap-lg-4 gap-14 align-items-center " style={{border:'1px solid rgba(223, 223, 223, 1)',borderRadius:'19px'}}>
                            <div>
                                 <img src={verimg3} className="" alt="" />
                            </div>
                            <div>
                               <h1 className="fw-600 fs-16 plus newfont-20 " style={{color:'rgba(24, 24, 24, 1)'}}>Landing Website</h1>
                               <p className="fs-13 lexend fw-400 newfont-13 d-sm-none d-none d-md-block d-lg-block" style={{color:'rgba(0, 0, 0, 0.45)'}}>Manage your website content effortlessly with our CMS solutions. We provide easy-to-use  platforms that allow you to update and maintain your site with minimal technical knowledge.</p>
                               <p className="fs-13 lexend fw-400 newfont-13 d-sm-block d-block d-md-none d-lg-none" style={{color:'rgba(0, 0, 0, 0.45)'}}>Manage your website content effortlessly with our CMS solutions.</p>

                            </div>
                        </div>

                    </div>
                    <div className="col-md-6 ">
                        <div className="d-flex gap-lg-4 gap-14 align-items-center " style={{border:'1px solid rgba(223, 223, 223, 1)',borderRadius:'19px'}}>
                            <div>
                                 <img src={verimg4} className="" alt="" />
                            </div>
                            <div>
                               <h1 className="fw-600 fs-16 plus newfont-16 " style={{color:'rgba(24, 24, 24, 1)'}}>Corporate Website</h1>
                               <p className="fs-13 lexend fw-400 newfont-13 d-sm-none d-none d-md-block d-lg-block" style={{color:'rgba(0, 0, 0, 0.45)'}}>Ensure your website looks great on all devices with our mobile-responsive design services. We  create sites that provide a seamless user experience across smartphones, tablets, and desktops.</p>
                               <p className="fs-13 lexend fw-400 newfont-13 d-sm-block d-block d-md-none d-lg-none " style={{color:'rgba(0, 0, 0, 0.45)'}}>your website looks great on all devices with our mobile-responsive design services.</p>

                            </div>
                        </div>

                    </div>
                    <div className="col-md-6 ">
                        <div className="d-flex gap-lg-4 gap-14 align-items-center " style={{border:'1px solid rgba(223, 223, 223, 1)',borderRadius:'19px'}}>
                            <div>
                                 <img src={verimg5} className="" alt="" />
                            </div>
                            <div>
                               <h1 className="fw-600 fs-16 plus newfont-16 " style={{color:'rgba(24, 24, 24, 1)'}}>Customize Website</h1>
                               <p className="fs-13 lexend fw-400 newfont-13 d-sm-none d-none d-md-block d-lg-block" style={{color:'rgba(0, 0, 0, 0.45)'}}>Increase your website's visibility on search engines with our SEO optimization services. We  implement strategies that drive organic traffic and improve your search engine rankings.</p>
                               <p className="fs-13 lexend fw-400 newfont-13 d-sm-block d-block d-md-none d-lg-none" style={{color:'rgba(0, 0, 0, 0.45)'}}>Increase your website's visibility on search engines with our SEO optimization services.</p>

                            </div>
                        </div>

                    </div>
                    <div className="col-md-6 ">
                        <div className="d-flex gap-lg-4 gap-14 align-items-center " style={{border:'1px solid rgba(223, 223, 223, 1)',borderRadius:'19px'}}>
                            <div>
                                 <img src={verimg6} className="" alt="" />
                            </div>
                            <div>
                               <h1 className="fw-600 fs-16 plus newfont-20 " style={{color:'rgba(24, 24, 24, 1)'}}>Blogging Website</h1>
                               <p className="fs-13 lexend fw-400 newfont-13 d-sm-none d-none d-md-block d-lg-block" style={{color:'rgba(0, 0, 0, 0.45)'}}>Make a lasting impression with our creative graphic design services. From logos to marketing  materials, we craft visually appealing designs that effectively communicate your brand’s  message.</p>
                               <p className="fs-13 lexend fw-400 newfont-13 d-sm-block d-block d-md-none d-lg-none" style={{color:'rgba(0, 0, 0, 0.45)'}}>Make a lasting impression with our creative graphic design services.</p>

                            </div>
                        </div>

                    </div>

                 
                </div>

            </div>
        </div>
    </div>
</section>

{/* Mobile view  */}
<section className='d-block d-sm-block d-lg-none d-md-none'>
  <div className='container'>
          <div className="row   mt-30" >
              <div className="col-md-12" >
                  <div className="row  g-3" >
                      <div className="col-6">
                          {/* <div  > */}
                              <div className='justify-content-center text-center'>
                                  <img style={{borderRadius:'19px',height:'150px',width:'150px'}} src={verimg1} alt="" />
                                <h1 className="fw-600 fs-16 plus newfont-20  pt-3" style={{color:'rgba(24, 24, 24, 1)'}}>Static Website</h1>

                              </div>
                              
                          {/* </div> */}

                      </div>
                      <div className="col-6">
                          {/* <div > */}
                              <div className='justify-content-center text-center'>
                                  <img style={{borderRadius:'19px',height:'150px',width:'150px'}} src={verimg2} className="" alt="" />
                                <h1 className="fw-600 fs-16 plus newfont-20 text-center pt-3" style={{color:'rgba(24, 24, 24, 1)'}}>Dynamic Website</h1>

                              </div>
                              
                          {/* </div> */}

                      </div>
                      <div className="col-6">
                          {/* <div className="d-flex gap-lg-4 gap-14 align-items-center " > */}
                              <div className='justify-content-center text-center'>
                                  <img style={{borderRadius:'19px',height:'150px',width:'150px'}} src={verimg3} className="" alt="" />
                                <h1 className="fw-600 fs-16 plus newfont-20 text-center pt-3" style={{color:'rgba(24, 24, 24, 1)'}}>Landing Website</h1>

                              </div>
                              
                          {/* </div> */}

                      </div>
                      <div className="col-6 ">
                          {/* <div className="d-flex gap-lg-4 gap-14 align-items-center " > */}
                              <div className='justify-content-center text-center'>
                                  <img style={{borderRadius:'19px',height:'150px',width:'150px'}} src={verimg4} className="" alt="" />
                                <h1 className="fw-600 fs-16 plus newfont-16 text-center pt-3" style={{color:'rgba(24, 24, 24, 1)'}}>Corporate Website</h1>

                              </div>
                              
                          {/* </div> */}

                      </div>
                      <div className="col-6 ">
                          {/* <div className="d-flex gap-lg-4 gap-14 align-items-center " > */}
                              <div className='justify-content-center text-center'>
                                  <img style={{borderRadius:'19px',height:'150px',width:'150px'}} src={verimg5} className="" alt="" />
                                <h1 className="fw-600 fs-16 plus newfont-16  pt-3" style={{color:'rgba(24, 24, 24, 1)'}}>Customize Website</h1>
                              </div>
                              
                          {/* </div> */}

                      </div>
                      <div className="col-6 ">
                          {/* <div className="d-flex gap-lg-4 gap-14 align-items-center " > */}
                              <div className='justify-content-center text-center'>
                                  <img  style={{borderRadius:'19px',height:'150px',width:'150px'}} src={verimg6} className="" alt="" />
                                <h1 className="fw-600 fs-16 plus newfont-20 text-center pt-3" style={{color:'rgba(24, 24, 24, 1)'}}>Blogging Website</h1>
                              </div>
                              
                          {/* </div> */}

                      </div>

                  
                  </div>

              </div>
          </div>
  </div>
</section>

<section className='d-none d-sm-none d-lg-block d-md-block'>
<div className="container projectcontainer1 mt-4">
  <p className='fs-38 fw-700 plus text-center font-25'>Our Best Projects</p>
  <div className="row mt-4 ">
  {webprojectdata?.map((item1, index) => (
    <div className="col-lg-4 mb-5 " eventKey={index}>
    <a href={item1.url} style={{textDecoration:'none'}}><div className="projectresponsive-box1 py-lg-4 px-lg-5 " style={{backgroundColor:item1.bgcolor}}>
      <div className="projectimage-container1 ">
          <img src={item1.img} alt="" className="projectresponsive-img1 img-fluid hover1" />
        </div>
        <h1 className="projecttitle1">{item1.heading}</h1>
        <p className="projectdescription1">
         {item1.title}
        </p>
       
      </div></a>
    </div>
    )  )}
  </div>
</div> 
</section>



<section className='d-block d-sm-block d-lg-none d-md-none'>
  <div className="container projectcontainer1 mt-4">
    <p className='fs-38 fw-700 plus text-center font-25'>Our Best Projects</p>

    <WebDevSwiper />
  </div>
</section>

<section className='d-none d-sm-none d-lg-block d-md-block'>
  <div className='container'>
    <p className='fs-35 fw-700 plus text-center font-23'>Customised your Website</p>
    <div className='row px-5' >
      
        <div className='col-lg-4'>
          <div style={{border: '1px solid #DCDCDC', padding:'20px',borderRadius:'12px'}}>
            <img style={{height:'45px',width:'150px'}}  src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/shopify.png" alt="" className="img-fluid" />
            <p className='fs-17 fw-600 plus text-left mt-3' style={{color:'#000000',lineHeight:'1'}}>Shopify website<br></br> Development</p>
            <p className='fs-14 fw-400 plus text-left' style={{color:'#00000080'}}>Shopify website development involves creating e-commerce platforms using the Shopify platform, which offers robust features and flexibility for online businesses.</p>
          </div>
        </div>
        <div className='col-lg-4'>
          <div style={{border: '1px solid #DCDCDC', padding:'20px',borderRadius:'12px'}}>
            <img style={{height:'45px',width:'150px'}}  src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/wixstudio.png" alt="" className="img-fluid" />
            <p className='fs-17 fw-600 plus text-left mt-3' style={{color:'#000000',lineHeight:'1'}}>Wix Studio website<br></br> Development</p>
            <p className='fs-14 fw-400 plus text-left' style={{color:'#00000080'}}>Shopify website development involves creating e-commerce platforms using the Shopify platform, which offers robust features and flexibility for online businesses.</p>
          </div>
        </div>
        <div className='col-lg-4'>
          <div style={{border: '1px solid #DCDCDC', padding:'20px',borderRadius:'12px'}}>
            <img style={{height:'45px',width:'150px'}}  src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/woocommerse.png" alt="" className="img-fluid" />
            <p className='fs-17 fw-600 plus text-left mt-3' style={{color:'#000000',lineHeight:'1'}}>Woo Commerce website<br></br> Development</p>
            <p className='fs-14 fw-400 plus text-left' style={{color:'#00000080'}}>Shopify website development involves creating e-commerce platforms using the Shopify platform, which offers robust features and flexibility for online businesses.</p>
          </div>
        </div>
      
    </div>
  </div>
</section>

{/* mobile view */}
<section className='d-block d-sm-block d-lg-none d-md-none'>
  <div className=" container  px-4 py-5">
   <p className='fs-35 fw-700 plus text-center font-23'>Customised your Website</p>
   <div>
    <WebDevShopifySlider />
    </div>
  </div>
</section>

      <section className=' container mt-lg-5'>
        <p className='fw-700 fs-35 plus text-center font-23' style={{color:'rgba(0, 0, 0, 1)'}}> Your Vision, Our Expertise </p>
      <div className='mt-4 mobilewrap' style={{display:'flex',justifyContent:'center'}}>
                       
 
                       <div className="hover-container">
                           <img className='imageFluid ' src={newwebdevimg6} alt='Icon2'  />
                           <div className='col-3-boxforcareer'>
                               <p className="plus fs-15 fw-600 ms-2 pt-1" style={{color:'rgba(51, 51, 51, 1)'}}>Custom Website</p>
                               <p className="plus fs-14 ms-2" style={{color:'rgba(53, 53, 53, 0.72)', textAlign:'left'}}>Tailored Digital Solutions to Meet Your Unique Business Needs to Growth.</p>
                        <a href='/service/custom-website-design-development-solutions' ><button style={{color:'#151515',border:'none'}} type="button" className="btn plus fs-14">
                                   <span className="learn-more">Read more</span>
                                   <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                               </button></a>
                           </div>
                       </div>

                       
                       <div className="hover-container">
                           <img className='imageFluid ' src={newwebdevimg7} alt='Icon2'  />
                           <div className='col-3-boxforcareer'>
                               <p className="plus fs-15 fw-600 ms-2 pt-1" style={{color:'rgba(51, 51, 51, 1)'}}>Mobile App Development</p>
                               <p className="plus fs-14 ms-2" style={{color:'rgba(53, 53, 53, 0.72)', textAlign:'left'}}>Building Intuitive and High-Performance Mobile App for All Platforms</p>
                               <a href='/service/mobile-app-design-development-solutions' > <button style={{color:'#151515',border:'none'}} type="button" className="btn plus fs-14">
                                   <span className="learn-more">Read more</span>
                                   <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                               </button></a>
                           </div>
                       </div>

                       
                       <div className="hover-container">
                           <img className='imageFluid ' src={newwebdevimg8} alt='Icon2'  />
                           <div className='col-3-boxforcareer'>
                               <p className="plus fs-15 fw-600 ms-2 pt-1" style={{color:'rgba(51, 51, 51, 1)'}}>E Commerce Website</p>
                               <p className="plus fs-14 ms-2" style={{color:'rgba(53, 53, 53, 0.72)', textAlign:'left'}}>Empowering Your Online Store with Seamless Ecommerce Development</p>
                               <a href='/service/ecommerce-website-design-development-solutions' >    <button style={{color:'#151515',border:'none'}} type="button" className="btn plus fs-14">
                                   <span className="learn-more">Read more</span>
                                   <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                               </button></a>
                           </div>
                       </div>

                       
                       <div className="hover-container">
                           <img className='imageFluid ' src={newwebdevimg9} alt='Icon2'  />
                           <div className='col-3-boxforcareer'>
                               <p className="plus fs-15 fw-600 ms-2 pt-1" style={{color:'rgba(51, 51, 51, 1)'}}>CMS Website</p>
                               <p className="plus fs-14 ms-2" style={{color:'rgba(53, 53, 53, 0.72)', textAlign:'left'}}>Efficient Content Management Solutions for Dynamic & Scalable Websites</p>
                               <a href='/service/cms-website-design-development-solutions' >    <button style={{color:'#151515',border:'none'}} type="button" className="btn plus fs-14">
                                   <span className="learn-more">Read more</span>
                                   <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                               </button></a>
                           </div>
                       </div>

                       
                       <div className="hover-container">
                           <img className='imageFluid ' src={newwebdevimg10} alt='Icon2'  />
                           <div className='col-3-boxforcareer'>
                               <p className="plus fs-15 fw-600 ms-2 pt-1" style={{color:'rgba(51, 51, 51, 1)'}}>SEO</p>
                               <p className="plus fs-14 ms-2" style={{color:'rgba(53, 53, 53, 0.72)', textAlign:'left'}}>Boost Your Search Rankings and Drive Organic Traffic for Your Business.</p>
                               <a href='/service/search-engine-optimization-seo' >     <button style={{color:'#151515',border:'none'}} type="button" className="btn plus fs-14">
                                   <span className="learn-more">Read more</span>
                                   <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                               </button></a>
                           </div>
                       </div>

                     
                     
                          
                   </div>
      </section>

      <section className="mt-lg-4 mt-10 ">
               <div>
                    <p className="fs-35 fw-700 plus text-center mb-0 font-23"> Cutting-Edge Technologies for </p>
                    <p className="fs-35 fw-700 plus text-center font-23">Modern Web Solutions</p>

               </div>
               <div className="container mt-lg-4 mt-30">
                    <img src={custompageimg} alt="" className="img-fluid" />
               </div>
              
            </section>

      <section className='d-none d-sm-none d-lg-block d-md-block'>

<div className="mt-4">
        <p className="fs-35 fw-700 plus text-center mb-0 font-20">Our Streamlined Web Development</p>
        <p className="fs-35 fw-700 plus text-center font-20"> Process from Concept to Launch</p>

 </div>
 <div className="container mt-lg-5 ">
<div className="row ms-lg-5">
  <div className="col-lg-4 ">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot1} alt=""  />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">
          1. Discovery and Planning
        </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
         Understanding your goals, target audience, and requirements to  lay a solid foundation for the project.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot2} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">2. Development </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Transforming designs into functional <br /> websites with
          clean code and seamless functionality.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot1} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">3. Launch</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Deploying the website to the live <br /> environment and
          making it accessible to your audience.
        </p>
      </div>
    </div>
  </div>
</div>
</div>
<div className="container">

<div className="col-lg-12 ms-lg-5">
<img className="img-fluid" src={stagesbg} alt="" />
</div>
</div>
<div className="container pt-4 ">
<div className="row ms-lg-5">
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot3} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">4. Design</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Bringing your vision to life with captivating visuals and
          intuitive user interfaces.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot4} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">5. Testing </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Thoroughly checking every aspect of the website to ensure
          it meets quality standards and performs flawlessly.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot3} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">6. Beyond</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Providing ongoing support, maintenance, and updates to
          ensure your website remains optimized and up to date.
        </p>
      </div>
    </div>
  </div>
</div>



</div>
    
</section>

{/* mobile view */}
<section className='d-block d-sm-block d-lg-none d-md-none'>
  <div className='container mt-4'>
  <p className="fs-35 fw-700 plus text-center mb-0 font-20">Our Streamlined Web Development</p>
  <p className="fs-35 fw-700 plus text-center font-20"> Process from Concept to Launch</p>
    <div className='row'>
      <div className='col-3'>
         <img style={{height:'532px'}} src="/assets/" alt="" className="img-fluid" />
      </div>
      <div className='col-9 mt-2'>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot1} alt=""  />
          </div>
          <div>
            <p className="sec5title fs-15 mb-0 mt-1">
              1. Discovery and Planning
            </p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12 "
            >
            Understanding your goals, target audience, and requirements to  lay a solid foundation for the project.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot2} alt="" />
          </div>
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">2. Development </p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Transforming designs into functional <br /> websites with
              clean code and seamless functionality.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot1} alt="" />
          </div>
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">3. Launch</p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Deploying the website to the live <br /> environment and
              making it accessible to your audience.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot3} alt="" />
          </div>
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">4. Design</p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Bringing your vision to life with captivating visuals and
              intuitive user interfaces.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot4} alt="" />
          </div>
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">5. Testing </p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Thoroughly checking every aspect of the website to ensure
              it meets quality standards and performs flawlessly.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot3} alt="" />
          </div>
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">6. Beyond</p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Providing ongoing support, maintenance, and updates to
              ensure your website remains optimized and up to date.
            </p>
          </div>
        </div>
      </div>
    </div>
    

  </div>
</section>

{/* <section className='d-block d-sm-block d-lg-none d-md-none'>
  <div className="container projectcontainer1 mt-4">
    <p className='fs-38 fw-700 plus text-center font-25'>Our Best Projects</p>

    <WebDevSwiper />
  </div>
</section>

<section className='d-none d-sm-none d-lg-block d-md-block'>
  <div className='container'>
    <p className='fs-35 fw-700 plus text-center font-23'>Customised your Website</p>
    <div className='row'>
      <div className='col-lg-3'>
        
      </div>
    </div>
  </div>
</section>

      <section className=' container'>
        <p className='fw-700 fs-35 plus text-center font-23' style={{color:'rgba(0, 0, 0, 1)'}}> Your Vision, Our Expertise </p>
      <div className='mt-4 mobilewrap' style={{display:'flex',justifyContent:'center'}}>
                       
 
                       <div className="hover-container">
                           <img className='imageFluid ' src={newwebdevimg6} alt='Icon2'  />
                           <div className='col-3-boxforcareer'>
                               <p className="plus fs-15 fw-600 ms-2 pt-1" style={{color:'rgba(51, 51, 51, 1)'}}>Custom Website</p>
                               <p className="plus fs-14 ms-2" style={{color:'rgba(53, 53, 53, 0.72)', textAlign:'left'}}>Tailored Digital Solutions to Meet Your Unique Business Needs to Growth.</p>
                        <a href='/service/custom-website-design-development-solutions' ><button style={{color:'#151515',border:'none'}} type="button" className="btn plus fs-14">
                                   <span className="learn-more">Read more</span>
                                   <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                               </button></a>
                           </div>
                       </div>

                       
                       <div className="hover-container">
                           <img className='imageFluid ' src={newwebdevimg7} alt='Icon2'  />
                           <div className='col-3-boxforcareer'>
                               <p className="plus fs-15 fw-600 ms-2 pt-1" style={{color:'rgba(51, 51, 51, 1)'}}>Mobile App Development</p>
                               <p className="plus fs-14 ms-2" style={{color:'rgba(53, 53, 53, 0.72)', textAlign:'left'}}>Building Intuitive and High-Performance Mobile App for All Platforms</p>
                               <a href='/service/mobile-app-design-development-solutions' > <button style={{color:'#151515',border:'none'}} type="button" className="btn plus fs-14">
                                   <span className="learn-more">Read more</span>
                                   <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                               </button></a>
                           </div>
                       </div>

                       
                       <div className="hover-container">
                           <img className='imageFluid ' src={newwebdevimg8} alt='Icon2'  />
                           <div className='col-3-boxforcareer'>
                               <p className="plus fs-15 fw-600 ms-2 pt-1" style={{color:'rgba(51, 51, 51, 1)'}}>E Commerce Website</p>
                               <p className="plus fs-14 ms-2" style={{color:'rgba(53, 53, 53, 0.72)', textAlign:'left'}}>Empowering Your Online Store with Seamless Ecommerce Development</p>
                               <a href='/service/ecommerce-website-design-development-solutions' >    <button style={{color:'#151515',border:'none'}} type="button" className="btn plus fs-14">
                                   <span className="learn-more">Read more</span>
                                   <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                               </button></a>
                           </div>
                       </div>

                       
                       <div className="hover-container">
                           <img className='imageFluid ' src={newwebdevimg9} alt='Icon2'  />
                           <div className='col-3-boxforcareer'>
                               <p className="plus fs-15 fw-600 ms-2 pt-1" style={{color:'rgba(51, 51, 51, 1)'}}>CMS Website</p>
                               <p className="plus fs-14 ms-2" style={{color:'rgba(53, 53, 53, 0.72)', textAlign:'left'}}>Efficient Content Management Solutions for Dynamic & Scalable Websites</p>
                               <a href='/service/cms-website-design-development-solutions' >    <button style={{color:'#151515',border:'none'}} type="button" className="btn plus fs-14">
                                   <span className="learn-more">Read more</span>
                                   <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                               </button></a>
                           </div>
                       </div>

                       
                       <div className="hover-container">
                           <img className='imageFluid ' src={newwebdevimg10} alt='Icon2'  />
                           <div className='col-3-boxforcareer'>
                               <p className="plus fs-15 fw-600 ms-2 pt-1" style={{color:'rgba(51, 51, 51, 1)'}}>SEO</p>
                               <p className="plus fs-14 ms-2" style={{color:'rgba(53, 53, 53, 0.72)', textAlign:'left'}}>Boost Your Search Rankings and Drive Organic Traffic for Your Business.</p>
                               <a href='/service/search-engine-optimization-seo' >     <button style={{color:'#151515',border:'none'}} type="button" className="btn plus fs-14">
                                   <span className="learn-more">Read more</span>
                                   <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                               </button></a>
                           </div>
                       </div>

                     
                     
                          
                   </div>
      </section>

      <section className="mt-lg-4 mt-10 ">
               <div>
                    <p className="fs-35 fw-700 plus text-center mb-0 font-23"> Cutting-Edge Technologies for </p>
                    <p className="fs-35 fw-700 plus text-center font-23">Modern Web Solutions</p>

               </div>
               <div className="container mt-lg-5 mt-30">
                    <img src={custompageimg} alt="" className="img-fluid" />
               </div>
              
            </section>

      <section>

<div className="mt-4">
        <p className="fs-35 fw-700 plus text-center mb-0 font-20">Our Streamlined Web Development</p>
        <p className="fs-35 fw-700 plus text-center font-20"> Process from Concept to Launch</p>

 </div>
 <div className="container mt-lg-5 ">
<div className="row ms-lg-5">
  <div className="col-lg-4 ">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot1} alt=""  />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">
          1. Discovery and Planning
        </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
         Understanding your goals, target audience, and requirements to  lay a solid foundation for the project.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot2} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">2. Development </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Transforming designs into functional <br /> websites with
          clean code and seamless functionality.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot1} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">3. Launch</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Deploying the website to the live <br /> environment and
          making it accessible to your audience.
        </p>
      </div>
    </div>
  </div>
</div>
</div>
<div className="container">

<div className="col-lg-12 ms-lg-5">
<img className="img-fluid" src={stagesbg} alt="" />
</div>
</div>
<div className="container pt-4 ">
<div className="row ms-lg-5">
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot3} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">4. Design</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Bringing your vision to life with captivating visuals and
          intuitive user interfaces.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot4} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">5. Testing </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Thoroughly checking every aspect of the website to ensure
          it meets quality standards and performs flawlessly.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot3} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">6. Beyond</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Providing ongoing support, maintenance, and updates to
          ensure your website remains optimized and up to date.
        </p>
      </div>
    </div>
  </div>
</div>



</div>
    
</section> */}


    

      {/* section 2 */}
      {/* <section>
        <div className="sec2 pt-lg-5 pt-20 pb-4 ">
          <p className="sec2heading text-center mb-0 Seopara1 font-23">
       
            We Build Websites That Grow Your Business
          </p>
        
          <div className="container mt-lg-5 mt-20">
              <div className="row justify-content-center">
                <div className="col-lg-4 textcenter">
                  <img src={newwebdev1} alt="" className="img-fluid " />
                </div>
                <div className="col-lg-5 ">
                  <p className="fs-18 plus textcenter font-18 mt-lg-5 mt-30 mb0" style={{color:'rgba(0, 0, 0, 0.6)'}}>We don't just build websites; we create online solutions that drive results. Our deep understanding of diverse business landscapes allows us to craft websites that perfectly align with your unique goals. We combine user-friendly design, cutting-edge technology, and a focus on conversions to deliver a website that elevates your online presence and fuels your success. </p>
                </div>
              </div>
          </div>
        </div>
      </section> */}
      {/* section 2 end */}

      {/* section 3 */}
      {/* <section>
        <div className="pt-lg-4">
          <p className="sec2heading text-center  font-23">
            
         
            Maximize Your Online Success with Our All - Inclusive Web Development Services
          </p>
        </div>
        <div className="sec3bg img-fluid pt-5 mt-lg-5 mt-20 ">
        

  

    <div className="container ">
  <div className="row card-design-responsive">
    <div className="col-lg-4 col-md-6 col-sm-12 pos-relative ">
      <div className="sec3div1 d-flex  align-items-center justify-content-center ">
        <div className="section-text text-center px-2">
          <h1 className="font-family tc fs-22 fw-600">Website Design & Development</h1>
          <p className="para ">Crafting bespoke websites that merge creativity  with functionality to elevate your online presence.</p>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6 col-sm-12 pos-relative ">
      <div className="sec3div1 d-flex align-items-center justify-content-center">
        <div className="section-text text-center">
          <h1 className="font-family tc fs-22 fw-600">E-commerce Development</h1>
          <p className="para">Building robust online stores that drive sales and  enhance customer experiences.</p>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6 col-sm-12 pos-relative ">
      <div className="sec3div1 d-flex align-items-center justify-content-center">
        <div className="section-text text-center">
          <h1 className="font-family tc fs-22 fw-600">Content Management Systems</h1>
          <p className="para">Empowering you with intuitive platforms to  manage and update your website content effortlessly.</p>
        </div>
      </div>
    </div>
    <div className="col-lg-2 d-none d-lg-block"></div>
    <div className="col-lg-4 col-md-6 col-sm-12 pos-relative ">
      <div className="sec3div1 d-flex align-items-center justify-content-center">
        <div className="section-text text-center ">
          <h1 className="font-family tc fs-22 fw-600">Website Maintenance & Support</h1>
          <p className="para">Ensuring your website remains secure, updated,  and optimized for peak performance.</p>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6 col-sm-12 pos-relative ">
      <div className="sec3div1 d-flex align-items-center justify-content-center">
        <div className="section-text text-center">
          <h1 className="font-family tc fs-22 fw-600">Responsive Design</h1>
          <p className="para">Creating seamless user experiences across all devices,  ensuring your website looks and functions flawlessly on every screen size.</p>
        </div>
      </div>
    </div>
    <div className="col-lg-2 d-none d-lg-block"></div>
  </div>
</div>    
        </div>
      </section> */}
   

      {/* <section>
              <div className="mt-lg-5 mt-10">
                  <p className="sec2heading text-center mb-0 Seopara1 font-23">  Your Vision, Our Expertise  </p>
                 
              </div>

              <div className="container mt-lg-5 mt-10">
                <div className="row">
                    <div className="col-lg-4">
                            <img src={webnewimg1} alt="" className="img-fluid "/>
                    </div>
                    <div className="col-lg-3 d-none d-sm-none d-lg-block d-md-block ">
                          <img src={webnewimg2} alt="" className="img-fluid "/>
                          <img src={webnewimg3} alt="" className="img-fluid pt-4 ps-1 pb-4"/>
                    </div>
                    <div className="col-lg-5 ">
                        <p className="font-18 pt-20 textcenter mt0" style={{fontSize:'18px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif" ,marginTop:'80px'}}> we understand that a professional website is more than just a digital presence; it's a powerful tool that drives business growth and success. Here’s how our expert web development services can transform your business </p>
                    </div>
                </div>

              </div>

            
      </section> */}

      {/* <section>
                <div className='container mt-lg-5 '>
                    <div className='row justify-content-center'>
                        <div className='col-lg-4'> 
                            <img src={webnewimg4} alt='' className='img-fluid '/>
                        </div>
                        <div className='col-lg-6 '> 
                        <div className='mt-lg-5 textcenter mt-10 '>
                            <h1 className="font-23" style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Enhanced Credibility and Trust</h1>
                            <p className="font-18" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> A professionally designed website establishes your brand's credibility, making a strong first impression on potential customers. Our designs are not only visually appealing but also trustworthy, instilling confidence in your audience.  </p>
                        </div>
                        </div>
                      
                    </div>
                </div>

                <div className='container mt-lg-5 mt-10'>
                    <div className='row justify-content-center'>

                    <div className='col-lg-6  d-none d-sm-none d-lg-block d-md-block'> 
                        <div className=' mt-lg-5 textcenter '>
                            <h1 className="font-23" style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Increased Visibility and Reach</h1>
                            <p className="font-18" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> With our SEO-optimized websites, your business will rank higher on search engines, increasing visibility and attracting a wider audience. This expanded reach can lead to more traffic, greater brand awareness, and higher conversion rates.  </p>
                        </div>
                        </div>

                        <div className='col-lg-4  d-none d-sm-none d-lg-block d-md-block'> 
                            <img src={webnewimg5} alt='' className='img-fluid  '/>
                        </div>
                      
                      
                    </div>
                </div>

                <div className='container mt-lg-5'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-4'> 
                            <img src={webnewimg6} alt='' className='img-fluid '/>
                        </div>
                        <div className='col-lg-6 '> 
                        <div className='mt-lg-5 mt-10 textcenter'>
                            <h1 className="font-23" style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Optimized User Experience</h1>
                            <p className="font-18" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> Our user-centric design approach ensures that your website is easy to navigate, with intuitive layouts and fast loading times. A seamless user experience keeps visitors engaged and encourages them to explore more of what you offer.   </p>
                        </div>
                        </div>
                      
                    </div>
                </div>

                <div className='container mt-5  d-none d-sm-none d-lg-block d-md-block'>
                    <div className='row justify-content-center'>

                    <div className='col-lg-6 '> 
                        <div className=' mt-5 '>
                            <h1 style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Responsive Design for All Devices</h1>
                            <p style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> We create websites that look and function flawlessly on all devices, from desktops to smartphones. This ensures that your audience can access your site anytime, anywhere, enhancing convenience and user satisfaction.    </p>
                        </div>
                        </div>

                        <div className='col-lg-4'> 
                            <img src={webnewimg7} alt='' className='img-fluid  '/>
                        </div>
                      
                      
                    </div>
                </div>

                <div className='container mt-lg-5 '>
                    <div className='row justify-content-center'>
                        <div className='col-lg-4'> 
                            <img src={webnewimg8} alt='' className='img-fluid '/>
                        </div>
                        <div className='col-lg-6 '> 
                        <div className=' mt-lg-5 mt-10 textcenter'>
                            <h1 className="font-23" style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Custom Solutions Tailored to Your Needs</h1>
                            <p className="font-18" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> We provide personalized web development solutions that cater to your specific business goals and requirements. Whether you need an e-commerce platform, a content management system, or a corporate site, we've got you covered. </p>
                        </div>
                        </div>
                      
                    </div>
                </div>

                <div className='container mt-lg-5'>
                    <div className='row justify-content-center'>

                    <div className='col-lg-6 '> 
                        <div className=' mt-lg-5  d-none d-sm-none d-lg-block d-md-block '>
                            <h1 style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Ongoing Support and Maintenance</h1>
                            <p style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> Your commitment to your success doesn’t end with the launch. We offer ongoing support and maintenance to ensure your website remains secure, up-to-date, and optimized for peak performance.     </p>
                        </div>
                        </div>

                        <div className='col-lg-4'> 
                            <img src={webnewimg9} alt='' className='img-fluid '/>
                        </div>
                      
                      
                    </div>
                </div>
            </section> */}

   
   
      {/* <section>
        <div className="sec4">
          <div className="mt-4">
         
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <img style={{ maxWidth: "100%" }} src={sec4img1} alt=""  className="img-fluid"/>
                </div>
                <div className="col-lg-7 col-md-6 col-sm-6 col-12">
                  <p className="para1 font-18 textcenter mb0">
                  We create a professional website that not only represents your brand effectively but also drives measurable business results. Let us help you unlock your business's full potential online.
                  </p>
                </div>
              </div>

            
            </div>
          </div>

       
          <div className="pt-4">
            <p className="sec2heading text-center mb-0 textcenter font-23">
              
              Our Streamlined Web Development Process from Concept to Launch
            </p>
         
          </div>
          <div className="container mt-5 ">
            <div className="row ms-lg-5">
              <div className="col-lg-4 ">
                <div className="d-flex  gap-2">
                  <div>
                    <img src={sec5dot1} alt=""  />
                  </div>
                  <div>
                    <p className="sec5title mb-0 mt-1">
                      1. Discovery and Planning
                    </p>
                    <p
                      style={{ paddingLeft: "17px !important" }}
                      className="para1"
                    >
                     Understanding your goals, target audience, and requirements to  lay a solid foundation for the project.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex  gap-2">
                  <div>
                    <img src={sec5dot2} alt="" />
                  </div>
                  <div>
                    <p className="sec5title mb-0 mt-1">2. Development </p>
                    <p
                      style={{ paddingLeft: "17px !important" }}
                      className="para1"
                    >
                      Transforming designs into functional <br /> websites with
                      clean code and seamless functionality.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex  gap-2">
                  <div>
                    <img src={sec5dot1} alt="" />
                  </div>
                  <div>
                    <p className="sec5title mb-0 mt-1">3. Launch</p>
                    <p
                      style={{ paddingLeft: "17px !important" }}
                      className="para1"
                    >
                      Deploying the website to the live <br /> environment and
                      making it accessible to your audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
          
            <div className="col-lg-12 ms-lg-5">
              <img className="img-fluid" src={stagesbg} alt="" />
            </div>
          </div>
          <div className="container pt-4 ">
            <div className="row ms-lg-5">
              <div className="col-lg-4">
                <div className="d-flex  gap-2">
                  <div>
                    <img src={sec5dot3} alt="" />
                  </div>
                  <div>
                    <p className="sec5title mb-0 mt-1">4. Design</p>
                    <p
                      style={{ paddingLeft: "17px !important" }}
                      className="para1"
                    >
                      Bringing your vision to life with captivating visuals and
                      intuitive user interfaces.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex  gap-2">
                  <div>
                    <img src={sec5dot4} alt="" />
                  </div>
                  <div>
                    <p className="sec5title mb-0 mt-1">5. Testing </p>
                    <p
                      style={{ paddingLeft: "17px !important" }}
                      className="para1"
                    >
                      Thoroughly checking every aspect of the website to ensure
                      it meets quality standards and performs flawlessly.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex  gap-2">
                  <div>
                    <img src={sec5dot3} alt="" />
                  </div>
                  <div>
                    <p className="sec5title mb-0 mt-1">6. Beyond</p>
                    <p
                      style={{ paddingLeft: "17px !important" }}
                      className="para1"
                    >
                      Providing ongoing support, maintenance, and updates to
                      ensure your website remains optimized and up to date.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-lg-5 pt-10">
              <p className="sec2heading text-center mb-0 Seopara1 font-23">
              
                Cutting-Edge Technologies for  Modern Web Solutions
              </p>
            
            </div>

            <div>
              <WebdevCard />
            </div>

          </div>
       
          <div className="container ">
            <div className="mx-lg-5 my-4 ">
              <Minicontact />
            </div>
          </div>

        

              <div>
                <Testimonial1/>
              </div>

        

 <section className='mt-lg-4 mt-20'>
<div>
      <p className='text-center fw-600 fs-35 plus font-23'>Latest From The <span style={{ color: 'rgba(0, 83, 154, 1)' }}>Blog</span></p>
  </div>
  <div className='mt-4'>
  <Blog1 categoryId={categoryId}/>
  </div>
</section> 



       
          <div className="mt-lg-5 mt-30">
          <Faq faqData={faqData}/>
          </div>
        </div>
      </section> */}

{/* <section>
        <div className='newhome-bg1 resnewhome-bg1 d-flex align-items-center'>  
  <div className='container p-20'>
    <div className='row justify-content-center align-items-center'>
      <div className='col-lg-7 col-md-6 col-12 textcenter '>
          <h2 className='fs-50 fw-700 plus text-white font-25 mt-35' >Transform Your Digital Presence</h2>
          <p className='fs-18 fw-400 plus font-17' style={{color:'rgba(192, 199, 206, 1)'}}>Ready to transform your digital presence? <br/><br/> Fill out the form below to get a free consultation or a  quote for our top-notch website development and digital marketing services. Let's take your  business to new heights together!</p>
      </div>
      <div className='col-lg-4 col-md-6 col-12 '>
         <div>
          <Form/>
     
         </div>
        </div>
    </div>
  </div>
  </div>
  
</section> */}

{/* <section className='d-none d-sm-none d-md-block d-lg-block'>
        <div className='mt-35 mt-4'>     
          <div className='newwebdevbg'>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-lg-6 d-flex align-items-center'>
            <div className='pt-40'>
            <p className='fs-38 fw-800 plus  font-23 ' style={{color:'rgba(255, 255, 255, 1)'}}>Ready to transform your digital presence?</p>
            <p className='fs-20 fw-400 plus   font-18 ' style={{color:'rgba(255, 255, 255, 0.6)'}}>Fill out the form below to get a free consultation or a  quote for our top-notch website development and digital marketing services. Let's take your  business to new heights together!</p>
          </div>
          </div>
          <div className='col-lg-1'></div>
          <div className='col-lg-4 '>
          <div>

          <WebDevForm/>
          </div>
          </div>
              </div>
            </div>

          </div>
      </div>
</section>



<section className='d-block d-sm-block d-md-none d-lg-none'>
<div className='mob-dev-mo-view mt-10'>

    <div className='container text-center' style={{paddingTop:'25px',paddingBottom:'25px'}}>
    <p className='fs-23 fw-800 plus ' style={{color:'rgba(255, 255, 255, 1)'}}>Ready to transform your digital presence?</p>
    <p className='fs-18 fw-400 plus  mb-0  ' style={{color:'rgba(255, 255, 255, 0.6)'}}>Fill out the form below to get a free consultation or a  quote for our top-notch website development and digital marketing services. Let's take your  business to new heights together!</p>
    <div className='row'>
        <div className='col-lg-4'>
        <WebDevForm/>
        </div>
    </div>
    </div>

</div>

</section>  */}

      <section>
      <div className="mt-lg-5 mt-10">
          {/* <Faq faqData={faqData}/> */}
          <Faqaccordian faqAccordianData={faqData}/>
          </div>
      </section>

     

      <section>
  <div>
    <Chatbox/>
  </div>
</section>

<section>
<div className='p-3 mt-2' style={{backgroundColor:'rgba(210, 234, 255, 1)'}}>
        <div className='row justify-content-center'>
          <div className='col-lg-8'>
            <div className='row'>
              <div className='col-md-3'>
                <p className='fw-700 fs-30 plus font-23 textcenter' style={{color:'rgba(0, 79, 146, 1)'}}>Explore More Services</p>

              </div>
              <div className='col-md-9'>
                <div className='d-none d-sm-none d-md-block d-lg-block'>
                <div className='d-flex  pt-3 ' >
                  <div>
                  <a href='/service/social-media-marketing' style={{textDecoration:'none'}}>  <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Social Media Marketing &nbsp; </p></a>
                  </div>
                  <div>
                      <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>| &nbsp;</p>
                  </div>
                  <div>
                  <a href='/services/performance-marketing' style={{textDecoration:'none'}}>          <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}> Performance Marketing &nbsp;</p></a>
                  </div>
                  <div>
                      <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>| &nbsp;</p>
                  </div>
                  <div>
                  <a href='/service/search-engine-optimization-seo' style={{textDecoration:'none'}}>          <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>SEO &nbsp;</p></a>
                  </div>
                  <div>
                      <p className='fs-16 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>| &nbsp;</p>
                  </div>
                  <div>
                  <a href='/services/digital-marketing' style={{textDecoration:'none'}}>          <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Digital Marketing &nbsp;</p></a>
                  </div>
                  <div>
                      <p className='fs-16 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>| &nbsp;</p>
                  </div>
                  <div>
                  <a href='/services/graphic-desinging' style={{textDecoration:'none'}}>            <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Graphic Designing</p></a>
                  </div>
                </div>
                </div>


                <div className='d-none d-sm-none d-md-block d-lg-block'>
                <div className='d-flex gap-1'>
                  <div>
                  <a href='/service/custom-website-design-development-solutions' style={{textDecoration:'none'}}>            <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Custom Development&nbsp; </p></a>
                  </div>
                  <div>
                      <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>| &nbsp;</p>
                  </div>
                  <div>
                  <a href='/service/ecommerce-website-design-development-solutions' style={{textDecoration:'none'}}>              <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}> E Commerce Website  &nbsp;</p></a>
                  </div>
                  <div>
                      <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>| &nbsp;</p>
                  </div>
                  <div>
                  <a href='/service/cms-website-design-development-solutions' style={{textDecoration:'none'}}>              <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>CMS Website &nbsp;</p></a>
                  </div>
                  <div>
                      <p className='fs-16 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>| &nbsp;</p>
                  </div>
                  <div>
                  <a href='/service/mobile-app-design-development-solutions' style={{textDecoration:'none'}}>            <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Mobile App Development &nbsp;</p></a>
                  </div>
                
                 
                </div>
                </div>

                <div className='d-block d-sm-block d-md-none d-lg-none'>
                  <div className='row'>
                    <div className='col-6'>
                    <div>
                    <a href='/service/social-media-marketing' style={{textDecoration:'none'}}>         <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Social media Marketing </p></a>
                    </div>

                    </div>
                    <div className='col-6'>
                    <div>
                    <a href='/services/performance-marketing' style={{textDecoration:'none'}}>      <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}> Performance Marketing  </p></a>
                    </div>

                    </div>

                  
                    <div className='col-6'>
                    <div>
                    <a href='/services/digital-marketing' style={{textDecoration:'none'}}>       <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Digital Marketing</p></a>
                    </div>

                    </div>

                    <div className='col-6'>
                    <div>
                    <a href='/services/graphic-desinging' style={{textDecoration:'none'}}>       <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Graphic Designing</p></a>
                    </div>

                    </div>
                    <div className='col-6'>
                    <div>
                    <a href='/service/custom-website-design-development-solutions' style={{textDecoration:'none'}}>      <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Custom Development</p></a>
                    </div>

                    </div>

                    <div className='col-6'>
                    <div>
                    <a href='/service/ecommerce-website-design-development-solutions' style={{textDecoration:'none'}}>    <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>E Commerce Website </p></a>
                    </div>

                    </div>
                    <div className='col-6'>
                    <div>
                    <a href='/service/cms-website-design-development-solutions' style={{textDecoration:'none'}}>    <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>CMS Website</p></a>
                    </div>

                    </div>
                    <div className='col-6'>
                    <div>
              <a href='/service/mobile-app-design-development-solutions' style={{textDecoration:'none'}}> <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Mobile App Development</p></a>
                    </div>

                    </div>

                  </div>
                </div>
                
                </div>
            </div>
          </div>
        </div>

</div>
</section>


     
    <div>
      <Footer />
      </div>
     
    </div>
  );
}
export default Webdev;
