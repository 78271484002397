import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import '../Components/webDevSwiper.css';
import { Pagination,Autoplay } from 'swiper/modules';

const WebDevShopifySlider = ({ categoryId }) => {

 

  return (
    <div>

<Swiper pagination={true} modules={[Pagination,Autoplay]} className="mySwiper"
      autoplay={{
        delay: 3000, 
        disableOnInteraction: false, 
      }}
      >
        <div >
            <SwiperSlide>
            <div style={{border: '1px solid #DCDCDC', padding:'20px',borderRadius:'12px'}}>
                <img style={{height:'45px',width:'180px'}}  src="/assets/shopify.png" alt="" className="img-fluid" />
                <p className='fs-17 fw-600 plus text-left mt-3' style={{color:'#000000',lineHeight:'1',textAlign:'left'}}>Shopify website Development</p>
                <p className='fs-14 fw-400 plus text-left' style={{color:'#00000080',textAlign:'left'}}>Shopify website development involves creating e-commerce platforms using the Shopify platform, which offers robust features and flexibility for online businesses.</p>
            </div>
            </SwiperSlide>
            <SwiperSlide>
                <div style={{border: '1px solid #DCDCDC', padding:'20px',borderRadius:'12px'}}>
                    <img style={{height:'45px'}}  src="/assets/wixstudio.png" alt="" className="img-fluid" />
                    <p className='fs-17 fw-600 plus text-left mt-3' style={{color:'#000000',lineHeight:'1',textAlign:'left'}}>Wix Studio website Development</p>
                    <p className='fs-14 fw-400 plus text-left' style={{color:'#00000080',textAlign:'left'}}>Shopify website development involves creating e-commerce platforms using the Shopify platform, which offers robust features and flexibility for online businesses.</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div style={{border: '1px solid #DCDCDC', padding:'20px',borderRadius:'12px'}}>
                    <img style={{height:'45px',width:'180px'}}  src="/assets/woocommerse.png" alt="" className="img-fluid" />
                    <p className='fs-17 fw-600 plus text-left mt-3' style={{color:'#000000',lineHeight:'1',textAlign:'left'}}>Woo Commerce website Development</p>
                    <p className='fs-14 fw-400 plus text-left' style={{color:'#00000080',textAlign:'left'}}>Shopify website development involves creating e-commerce platforms using the Shopify platform, which offers robust features and flexibility for online businesses.</p>
                </div>
            </SwiperSlide>
            
        </div>
        
      </Swiper>

 



    </div>
  );
};

export default WebDevShopifySlider;
