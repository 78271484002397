import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const WebDevForm = ()=>{

    // ================ Mailer code start from here =====================
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    service: "",
    phone: "",
  });
  const [responseMessage, setResponseMessage] = useState(""); 
  const [loading, setLoading] = useState(false); // State for loader
  const navigate = useNavigate(); // Initialize useNavigate
  const [errors, setErrors] = useState({}); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

 
  const validateForm = () => {
    let formErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/; // Assumes phone number should be 10 digits

    if (!formData.email || !emailRegex.test(formData.email)) {
      formErrors.email = "Please enter a valid email address.";
    }

    if (!formData.phone || !phoneRegex.test(formData.phone)) {
      formErrors.phone = "Please enter a valid 10-digit phone number.";
    }

    // Validate service selection
  if (!formData.service || formData.service === "Service Name") {
    formErrors.service = "Please select a valid service.";
  }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    setLoading(true); // Show loader


    try {
      const response = await fetch(
        "https://mailer.datametricks.com/send-email-1.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json(); // Parse JSON from the response

      setResponseMessage(result.message);

      if (result.status === "success") {
        setTimeout(() => {
          setLoading(false); // Hide loader
          navigate("/Successemailpage"); // Redirect to Thank You page
        }, 1000); // Delay redirect by 2 seconds
      } else {
        setLoading(false); // Hide loader if not successful
      }
    } catch (error) {
      setResponseMessage("Error submitting form.");
      setLoading(false); // Hide loader on error
    }
  };

  // ================ Mailer code end here =====================


    return(

        <div>
             <form onSubmit={handleSubmit}>

        <div className='p-5 mt-10' style={{background:'#FFFFFF1A',border:'0.6px solid #FFFFFF',borderRadius:'10px',backdropFilter:'blur(44.900001525878906px)'}}>
        <style>{`#fname::placeholder {color: #FFFFFF; /* White color for placeholder text */}`}</style>

              <p className='fs-19 fw-500 plus mb-4 text-left' style={{color:'#FFFFFF'}}>Get a Free Consultaion</p>
              <div class="custom-input-container">
                <input style={{border:' 0.96px solid #E4EBF3',background:'#FFFFFF1A',color:'#FFFFFF'}} type="text"  
                              id="fname"
                              name="name"
                              value={formData.name}
                              onChange={handleChange} placeholder="Full Name"  required />
                {/* <label for="customFullName fs-15 fw-400" style={{background:'#FFFFFF1A',color:'#E4EBF3'}}>* Full Name </label> */}
            </div>
            <div class="custom-input-container mt-4 ">
            <style>{`#phone::placeholder {color: #FFFFFF; /* White color for placeholder text */}`}</style>
                <input style={{border:' 0.96px solid #E4EBF3',background:'#FFFFFF1A',color:'#FFFFFF'}} type="tel" 
                             id="phone" 
                             name="phone"
                            value={formData.phone}
                            onChange={handleChange} 
                            placeholder="Mobile Number" required />
                {/* <label for="mobilenumber">Mobile Number </label> */}
                {errors.phone && <p className="error-text" style={{ fontSize: "12px", color: "red", marginTop: "10px" }}>{errors.phone}</p>}

            </div>
            <div class="custom-input-container mt-4 ">
             <style>{`#email::placeholder {color: #FFFFFF; /* White color for placeholder text */}`}</style>
                <input  style={{border:' 0.96px solid #E4EBF3',background:'#FFFFFF1A',color:'#FFFFFF',}} type="email"
                 id="email" name="email"  value={formData.email}
                              onChange={handleChange}
                               placeholder="Email" required />
                {/* <label for="email">* Email</label> */}
                {errors.email && <p className="error-text" style={{ fontSize: "12px", color: "red", marginTop: "5px" }}>{errors.email}</p>}

            </div>
            
            <div className="custom-input-container mt-4 ">
            <select
  className="custom-select"
  name="service"
  value={formData.service}
  onChange={handleChange}
  required
  form="usrform"
>
  <option value="" style={{ color: '#000000' }}>
    Service Name
  </option>
  <option value="webDevelopment" style={{ color: '#000000' }}>Web Development</option>
  <option value="mobileDevelopment" style={{ color: '#000000' }}>Mobile Development</option>
  <option value="socialMedia" style={{ color: '#000000' }}>Social Media</option>
  <option value="digitalMarketing" style={{ color: '#000000' }}>Digital Marketing</option>
  <option value="performanceMarketing" style={{ color: '#000000' }}>Performance Marketing</option>
  <option value="graphicDesign" style={{ color: '#000000' }}>Graphic Design</option>
  <option value="seo" style={{ color: '#000000' }}>SEO</option>
</select>
                
                

                {errors.service && (
                    <p
                    className="error-text"
                    style={{ fontSize: "12px", color: "red", marginTop: "5px" }}
                    >
                    {errors.service}
                    </p>
                )}
            </div>

            <div className='mt-4 '>
            <button  type="submit" class="btn  w-100 submit-button  plus text-white  mb-2 py-2" style={{backgroundColor:' rgba(0, 83, 154, 1)',boxShadow:'none',border:'none',outline:'none',fontSize:'20px',fontWeight:'600'}} disabled={loading}>
            {loading ? (
                            <>
                        
                              <div className="spinner"></div> {/* Spinner */}
                              {/* <span className="">Sending...</span> */}
                            
                            </>
                          ) : (
                            "Submit"
                          )}
                
                </button>
            </div>
          </div>
          </form>

        </div>
       
    )
}
export default WebDevForm;